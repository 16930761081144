import { type AxiosResponse } from 'axios'
import { type RuntimeConfig } from 'nuxt/schema'
import { AxiosInstance as axios } from '../services/responseHandler'
import { type GetawayData } from '~/types/getaways'
import { type GeoCodeItem, type GeoSuggestionData } from '../services/geoService'
import {
  type GroupMember,
  type GroupData,
  GroupLeaderboardEntry,
  GroupStatus,
  GroupPostData
} from '~/types/groups'
import { TripPrivacy, type TripData } from '~/types/trip'
import { type Waypoint } from '~/types/types'
import { type PaginationResponse } from '~/types/api'
import { SeasonGoalEntry, type UserProfile } from '~/types/user'
import { Posting, PostingComment } from '~/types/postings'
import { SavedRoute } from '~/types/route'
import { CountryRanking, Ranking, UserStats } from '~/types/statistics'

let config: RuntimeConfig
let token = ''
let refreshToken = ''

export const setAPIToken = (value: string) => {
  token = value
}

export const setRefreshToken = (value: string) => {
  refreshToken = value
}

export const setRuntimeConfig = (c: RuntimeConfig) => {
  config = c
}

export const retryRequest = async (config: any) => {
  const headers: Record<string, any> = { Authorization: 'Token token=' + token }
  return await axios({
    ...config,
    headers
  })
}

const apiRequest = async <T = any>({
  method = 'GET',
  url,
  apiUrl = config.public.apiUrl,
  apiPath = 'api/v2/',
  data,
  appAuth,
  req,
  silent = false,
  authorize = true
}: any): Promise<AxiosResponse<T | undefined | null>> => {
  const headers: Record<string, any> = {
    'riser-client-platform': 'web',
    'riser-client-version': '1.0.0'
  }

  if (authorize) {
    headers['Authorization'] = 'Token token=' + token
  }

  const baseURL = import.meta.env.SSR ? config.public.serverAPIURL : apiUrl
  if (process.server && req) {
    const tmpHeader = req?.headers ? Object.assign({}, req.headers) : {}
    headers['x-forwarded-for'] = tmpHeader['x-forwarded-for'] || req.socket.remoteAddress
  }

  // Prepare parameters for axios request
  let parameters = {
    method,
    url: baseURL + apiPath + url,
    data,
    headers,
    params: method === 'GET' ? data : {},
    silent
  }
  // Make sure url has a trailing slash
  if (parameters.url.substr(-1) !== '/' && !parameters.url.includes('?')) {
    parameters.url += '/'
  }
  if (appAuth) {
    // Add app credentials if necessary
    parameters = {
      ...parameters,
      ...{
        auth: {
          username: config.public.apiUsername,
          password: config.public.apiPassword
        },
        withCredentials: true
      }
    }
  }
  const response = await axios(parameters)
  return response
}

export const API = {
  refreshSession: async () => {
    const response = await apiRequest({
      method: 'POST',
      url: 'sessions',
      data: { refresh_token: refreshToken },
      appAuth: true,
      refreshToken: false
    })
    return response
  },

  login: async (email: string, password: string) => {
    return await apiRequest({
      method: 'POST',
      url: 'sessions/',
      data: { email, password },
      appAuth: true
    })
  },

  loginWithGoogle: async (data: { google_auth_token: string }) => {
    return await apiRequest({
      method: 'POST',
      url: 'sessions/',
      data,
      appAuth: true
    })
  },

  loginWithApple: async (data: any) => {
    return await apiRequest({
      method: 'POST',
      url: 'sessions',
      appAuth: true,
      data
    })
  },

  loginWithFacebook: async (fbtoken: string) => {
    return await apiRequest({
      method: 'POST',
      url: 'sessions',
      appAuth: true,
      data: { fbtoken }
    })
  },

  signup: async (
    email: string,
    password: string,
    firstname: string,
    lastname: string,
    locale: string,
    newsletter: boolean
  ) => {
    return await apiRequest({
      method: 'POST',
      url: 'users',
      data: {
        email,
        password,
        firstname,
        lastname,
        locale,
        newsletter
      },
      appAuth: true
    })
  },

  verifyUniqueEmail: async (email: string) => {
    return await apiRequest({
      method: 'GET',
      url: 'users/unique/',
      data: { email },
      appAuth: true,
      silent: true
    })
  },

  verifyEmail: async (userID: string, data: { email_token: string }) => {
    return await apiRequest({
      method: 'POST',
      url: `users/${userID}/verify_email/`,
      data,
      appAuth: true
    })
  },

  getUserStats: async (userID: number, from?: Date, to?: Date) => {
    return await apiRequest<UserStats>({
      method: 'GET',
      url: `/users/${userID}/stats`,
      data: {
        from: from?.toISOString(),
        to: to?.toISOString()
      }
    })
  },

  getUserCountryRanking: async (from?: Date, to?: Date) => {
    return await apiRequest<CountryRanking>({
      method: 'GET',
      url: `/country_rankings`,
      data: {
        from: from?.toISOString(),
        to: to?.toISOString()
      }
    })
  },

  getUserFollowedRanking: async (from?: Date, to?: Date) => {
    return await apiRequest<Ranking>({
      method: 'GET',
      url: `/followed_rankings`,
      data: {
        from: from?.toISOString(),
        to: to?.toISOString()
      }
    })
  },

  getUserTrips: async (id: number | string, privacy?: TripPrivacy) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/trips',
      data: { privacy }
    })
    return tripResponse
  },

  getTimeline: async (userID: string) => {
    return await apiRequest({ method: 'GET', url: 'users/' + userID + '/timeline' })
  },

  getUserBikes: async (id: number) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/bikes',
      appAuth: token === null
    })
    return tripResponse
  },

  getUserNotifications: async (id: string) => {
    const response: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/notifications',
      appAuth: token === null
    })
    return response
  },

  getUserSections: async (id: number) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/sections',
      appAuth: token === null
    })
    return tripResponse
  },

  getUserGetaways: async (id: number) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/getaways',
      appAuth: token === null
    })
    return tripResponse
  },

  getUserRoadbookSections: async (id: number) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'users/' + id + '/roadbook_sections',
      appAuth: token === null
    })
    return tripResponse
  },

  getTrip: async (id: string) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      url: 'trips/' + id,
      appAuth: token === null
    })
    return tripResponse
  },

  calculateTripRoute: async (id: number) => {
    return await apiRequest<{
      calculation_time: number
      code: string
      routes: any[]
      uuid: string
      waypoints: {
        name: string
        location: number[]
      }[]
    }>({
      method: 'POST',
      url: 'trips/' + id + '/route'
    })
  },

  createEvent: async (identifier: string, action: string, type: string) => {
    const response = await apiRequest({
      method: 'POST',
      url: 'events',
      data: {
        action,
        type,
        identifier
      }
    })
    return response
  },

  getPublicTrip: async (id: string) => {
    const tripResponse: any = await apiRequest({
      method: 'GET',
      apiPath: 'api/',
      url: 'sharing/trips/' + id + '/standard',
      appAuth: true
    })
    return tripResponse
  },

  putTrip: async (
    id: string,
    data: {
      title: string
      note: string
      weather: any
      friend_ids: string[]
      hide_max_speed: boolean
      bike_id: string
      truncate_trails: boolean
      privacy: string
    }
  ): Promise<TripData> => {
    const tripResponse: any = await apiRequest({ method: 'PUT', url: 'trips/' + id, data })
    return tripResponse.data
  },

  putProfilePhoto: async (userID: string, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: 'users/' + userID + '/profile_photo',
      data,
      form: true
    })
  },

  putCoverPhoto: async (userID: string, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: 'users/' + userID + '/cover_photo',
      data,
      form: true
    })
  },

  putTripPhotos: async (data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: '/sections/photos',
      data,
      form: true
    })
  },

  deleteTrip: async (id: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: '/trips/' + id
    })
  },

  deletePhoto: async (id: string) => {
    await apiRequest({
      method: 'DELETE',
      url: '/photos/' + id
    })
  },

  createPosting: async (post: { note: string; trip_id: number | null; files: File[] | null }) => {
    const data = new FormData()

    data.append('note', post.note)

    if (post.files && post.files.length > 0) {
      data.append('files', post.files[0])
    }

    return await apiRequest<Posting>({
      url: '/postings/',
      method: 'POST',
      data
    })
  },

  getPosting: async (id: string) => {
    return await apiRequest({
      url: `/postings/${id}/`,
      method: 'GET'
    })
  },

  putPosting: async ({ note, id }: { note: string; id: string }) => {
    await apiRequest({
      url: `/postings/${id}`,
      method: 'PUT',
      data: { note }
    })
  },

  deletePosting: async ({ id }: { id: string | number }) => {
    return await apiRequest({
      url: `/postings/${id}`,
      method: 'DELETE'
    })
  },

  reportPosting: async ({ id, note, reason }: { id: string; note: string; reason: string }) => {
    return await apiRequest({
      url: `/postings/${id}/complaints/`,
      method: 'POST',
      data: { note, reason }
    })
  },

  addPostingPhoto: async ({ data, id }: { data: FormData; id: string }) => {
    return await apiRequest({
      url: `/postings/${id}/photos/`,
      method: 'POST',
      data
    })
  },

  deletePostingPhotos: async ({ photoID, id }: { photoID: string; id: string }) => {
    return await apiRequest({
      url: `/postings/${id}/photos/${photoID}`,
      method: 'DELETE'
    })
  },

  createComment: async (endpoint: string, data: { text: string; timestamp: Date }) => {
    return await apiRequest({
      method: 'POST',
      url: endpoint,
      data
    })
  },

  deleteComment: async (endpoint: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: endpoint
    })
  },

  putComment: async (endpoint: string, data: { text: string; timestamp: Date }) => {
    return await apiRequest({
      method: 'PUT',
      url: endpoint,
      data
    })
  },

  getComments: async (endpoint: string) => {
    return await apiRequest<PostingComment[]>({ method: 'GET', url: endpoint })
  },

  reportComment: async (endpoint: string, note: string, reason: string) => {
    return await apiRequest<PostingComment>({
      method: 'POST',
      url: endpoint,
      data: {
        note,
        reason
      }
    })
  },

  createLike: async (endpoint: string) => {
    return await apiRequest({
      method: 'POST',
      url: endpoint,
      data: { timestamp: new Date() }
    })
  },

  deleteLike: async (endpoint: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: endpoint
    })
  },

  getLikes: async (endpoint: string) => {
    return await apiRequest({
      method: 'get',
      url: endpoint
    })
  },

  createSeasonGoal: async (data: { start_date: Date; end_date: Date; distance_goal: number }) => {
    return await apiRequest({
      method: 'POST',
      url: '/season_goals/',
      data
    })
  },

  putSeasonGoal: async (
    goaldID: number,
    data: { start_date: Date; end_date: Date; distance_goal: number }
  ) => {
    return await apiRequest({
      method: 'PUT',
      url: `/season_goals/${goaldID}`,
      data
    })
  },

  getSeasonGoal: async () => {
    return await apiRequest<SeasonGoalEntry[]>({
      method: 'GET',
      url: '/season_goals/'
    })
  },

  deleteSeasonGoal: async (goaldID: number) => {
    return await apiRequest({
      method: 'DELETE',
      url: `/season_goals/${goaldID}`
    })
  },

  getRoutes: async (userID: number) => {
    return await apiRequest({
      method: 'GET',
      url: `/users/${userID}/saved_routes/?includeData=true`
    })
  },

  getRoute: async (userID: number, routeID: string | number) => {
    return await apiRequest<SavedRoute>({
      method: 'GET',
      url: `/users/${userID}/saved_routes/${routeID}`
    })
  },

  createRoute: async (
    userID: number,
    title: string,
    distance: number,
    duration: number,
    waypoints: Waypoint[],
    weighting: string,
    tags: [],
    exclude: string[]
  ) => {
    return await apiRequest({
      method: 'POST',
      url: `users/${userID}/saved_routes`,
      data: {
        title,
        data: {
          distance,
          duration,
          waypoints,
          weighting,
          tags: [],
          exclude
        }
      }
    })
  },
  putRoute: async (
    userID: number,
    routeID: number,
    title: string,
    distance: number,
    duration: number,
    waypoints: Waypoint[],
    weighting: string,
    tags: [],
    exclude: string[]
  ) => {
    return await apiRequest({
      method: 'PUT',
      url: `users/${userID}/saved_routes/${routeID}`,
      data: {
        title,
        data: {
          distance,
          duration,
          waypoints,
          weighting,
          tags: [],
          exclude
        }
      }
    })
  },
  deleteRoute: async (userID: number, routeID: number) => {
    await apiRequest({
      method: 'DELETE',
      url: 'users/' + userID + '/saved_routes/' + routeID
    })
  },

  getSection: async (id: string, full = false) => {
    return await apiRequest({
      method: 'GET',
      url: `sections/${id}/${full ? 'full_locations' : ''}`,
      appAuth: token === null
    })
  },

  getSectionGeoJSON: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: `sections/${id}/geojson`,
      appAuth: token === null
    })
  },

  getSectionWeather: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: '/sections/' + id + '/weather',
      appAuth: token === null
    })
  },

  getUser: async (id: string | number) => {
    return await apiRequest<UserProfile>({
      method: 'GET',
      url: 'users/' + id
    })
  },

  deleteUser: async (id: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: '/users/' + id
    })
  },

  putUser: async (
    id: string | number,
    data: {
      note?: string
      youtube_url?: string
      facebook_url?: string
      instagram_url?: string
      website_url?: string
      homebase?: string
    }
  ) => {
    return await apiRequest({
      method: 'PUT',
      url: '/users/' + id,
      data
    })
  },

  getSuggestedFollows: async (userId: number) => {
    return await apiRequest<UserProfile[]>({
      url: 'users/' + userId + '/suggested_follows'
    })
  },

  getFollowers: async (userID: number, searchTerm?: string, limit?: number, after?: string) => {
    return await apiRequest<PaginationResponse<UserProfile>>({
      url: `users/${userID}/followers`,
      data: { limit, after, q: searchTerm }
    })
  },

  getFollowed: async (userID: number, searchTerm?: string, limit?: number, after?: string) => {
    return await apiRequest<PaginationResponse<UserProfile>>({
      url: `users/${userID}/followed`,
      data: { limit, after, q: searchTerm }
    })
  },

  getFollowRequests: async (
    userID: number,
    searchTerm?: string,
    limit?: number,
    after?: string
  ) => {
    return await apiRequest<PaginationResponse<UserProfile>>({
      url: `users/${userID}/incoming_follow_requests`,
      data: { limit, after, q: searchTerm }
    })
  },

  getBlockedUsers: async (userID: number, searchTerm?: string, limit?: number, after?: string) => {
    return await apiRequest<PaginationResponse<UserProfile>>({
      url: `users/${userID}/blocked_users`,
      data: { limit, after, q: searchTerm }
    })
  },

  acceptFollowRequest: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/accept_follow`
    })
  },

  rejectFollowRequest: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/remove_follow`
    })
  },

  follow: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/follow`
    })
  },

  unFollow: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/unfollow`
    })
  },

  removeFollower: async (followerID: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${followerID}/remove_follow`
    })
  },

  blockUser: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/block`
    })
  },

  unBlockUser: async (userId: number) => {
    return await apiRequest<UserProfile>({
      method: 'POST',
      url: `users/${userId}/unblock`
    })
  },

  getBike: async (bikeID: string) => {
    return await apiRequest({
      method: 'GET',
      url: 'bikes/' + bikeID
    })
  },

  createBike: async (
    userID: string,
    data: { nickname: string; build_year: number; hp: number; model_id: number }
  ) => {
    return await apiRequest({
      method: 'POST',
      url: 'users/' + userID + '/bikes',
      data
    })
  },

  putBike: async (
    bikeID: string,
    data: { nickname: string; build_year: number; hp: number; model_id: number }
  ) => {
    return await apiRequest({
      method: 'PUT',
      url: `/bikes/${bikeID}`,
      data
    })
  },

  putBikePhoto: async (bikeID: string, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: `/bikes/${bikeID}/photo`,
      data,
      form: true
    })
  },

  deleteBike: async (bikeID: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: `/bikes/${bikeID}`
    })
  },

  getBikeModels: async () => {
    return await apiRequest({
      method: 'GET',
      url: 'bikes/brands',
      appAuth: true
    })
  },

  getGroup: async (groupID: string | number, fetchAnonymously: boolean = false) => {
    return await apiRequest<GroupData>({
      method: 'GET',
      url: `groups/${groupID}`,
      authorize: !fetchAnonymously
    })
  },

  createGroup: async (userID: string | number, data: GroupPostData) => {
    console.log(data)
    return await apiRequest<GroupData>({
      method: 'POST',
      url: `users/${userID}/groups`,
      data
    })
  },

  putGroup: async (groupID: string | number, data: GroupPostData) => {
    return await apiRequest<GroupData>({
      method: 'PUT',
      url: `groups/${groupID}`,
      data
    })
  },

  deleteGroup: async (groupID: string | number) => {
    return await apiRequest({
      method: 'DELETE',
      url: `groups/${groupID}`
    })
  },

  updateGroupCoverImage: async (id: string | number, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: 'groups/' + id + '/cover_photo',
      data,
      form: true
    })
  },

  updateGroupProfileImage: async (id: string | number, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: 'groups/' + id + '/profile_photo',
      data,
      form: true
    })
  },

  getGroupTimeline: async (groupID: string | number) => {
    return await apiRequest<
      {
        trip: TripData
        timestamp: string
      }[]
    >({
      method: 'GET',
      url: `groups/${groupID}/timeline/`
    })
  },

  getGroupStatus: async (groupID: string | number, userID: string | number) => {
    return await apiRequest<{
      group_id: number
      status: GroupStatus
    }>({
      method: 'GET',
      url: `groups/${groupID}/status/${userID}`
    })
  },

  createGroupPosting: async (
    groupID: number,
    post: { note: string; files: File[]; tripIds: number[] }
  ) => {
    const data = new FormData()

    data.append('note', post.note)

    if (post.files.length > 0) {
      data.append('files', post.files[0])
    }

    if (post.tripIds.length > 0) {
      data.append('trips', post.tripIds[0].toString())
    }

    return await apiRequest({
      method: 'POST',
      url: `groups/${groupID}/postings`,
      data
    })
  },

  deleteGroupPosting: async (groupId: number, postingId: number) => {
    return await apiRequest({
      method: 'DELETE',
      url: `groups/${groupId}/postings/${postingId}`
    })
  },

  getGroupPostings: async (groupID: number, limit?: number, after?: string) => {
    return await apiRequest<PaginationResponse<Posting>>({
      url: `groups/${groupID}/postings`,
      data: { limit, after }
    })
  },

  likeGroupPosting: async (groupId: number, postingId: number) => {
    return await apiRequest({
      method: 'POST',
      url: `/groups/${groupId}/postings/${postingId}/likes`
    })
  },

  unlikeGroupPosting: async (groupId: number, postingId: number) => {
    return await apiRequest({
      method: 'DELETE',
      url: `/groups/${groupId}/postings/${postingId}/likes`
    })
  },

  getYearlyGroupLeaderboard: async (groupID: number) => {
    return await apiRequest<GroupLeaderboardEntry[]>({
      method: 'GET',
      url: `groups/${groupID}/leaderboard/year`
    })
  },

  getMonthlyGroupLeaderboard: async (groupID: number) => {
    return await apiRequest<GroupLeaderboardEntry[]>({
      method: 'GET',
      url: `groups/${groupID}/leaderboard/month`
    })
  },

  getAllTimeGroupLeaderboard: async (groupID: number) => {
    return await apiRequest<GroupLeaderboardEntry[]>({
      method: 'GET',
      url: `groups/${groupID}/leaderboard/alltime`
    })
  },

  getGroupInvitees: async (groupID: string | number) => {
    return await apiRequest<GroupMember[]>({
      method: 'GET',
      url: `groups/${groupID}/invited`
    })
  },

  getGroupMembers: async (groupID: string | number) => {
    return await apiRequest<GroupMember[]>({
      method: 'GET',
      url: `groups/${groupID}/members`
    })
  },

  getGroupRequests: async (groupID: string | number) => {
    return await apiRequest<GroupMember[]>({
      method: 'GET',
      url: `groups/${groupID}/requested`
    })
  },

  inviteToGroup: async (groupID: string | number, data: { user_ids: (string | number)[] }) => {
    return await apiRequest<GroupMember[]>({
      method: 'POST',
      url: 'groups/' + groupID + '/invite/',
      data
    })
  },

  getUserGroups: async (userID: string | number) => {
    return await apiRequest<GroupData[]>({
      method: 'GET',
      url: 'users/' + userID + '/groups/'
    })
  },

  getUserGroupRequests: async (userID: string | number) => {
    return await apiRequest({
      method: 'GET',
      url: 'users/' + userID + '/group_requests/'
    })
  },

  getUserGroupInvites: async (userID: string | number) => {
    return await apiRequest<GroupData[]>({
      method: 'GET',
      url: 'users/' + userID + '/group_invitations/'
    })
  },

  acceptGroupInvite: async (groupID: string | number) => {
    return await apiRequest({
      method: 'POST',
      url: 'groups/' + groupID + '/accept_invite/'
    })
  },

  acceptGroupJoinRequest: async (groupID: string | number, userIDs: (string | number)[]) => {
    return await apiRequest({
      method: 'POST',
      url: 'groups/' + groupID + '/accept_requests/',
      data: {
        user_ids: userIDs
      }
    })
  },

  createGroupRequest: async (groupID: string | number) => {
    return await apiRequest({
      method: 'POST',
      url: 'groups/' + groupID + '/request/'
    })
  },

  deleteGroupRequest: async (groupID: string | number, userID: string | number) => {
    return await apiRequest({
      method: 'DELETE',
      url: 'groups/' + groupID + '/members/' + userID
    })
  },

  getGetaway: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: `/getaways/${id}`
    })
  },

  getGetawayInvitations: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: `getaways/${id}/getaway_invitations`
    })
  },

  deleteGetaway: async (id: number) => {
    return await apiRequest({
      method: 'DELETE',
      url: `/getaways/${id}`
    })
  },

  putGetaway: async (id: number, data: Partial<GetawayData>) => {
    return await apiRequest({
      method: 'PUT',
      url: `/getaways/${id}`,
      data
    })
  },

  createGetawayInvitation: async (id: string | number, data: { user_ids: (string | number)[] }) => {
    return await apiRequest<GetawayData>({
      method: 'POST',
      url: `/getaways/${id}/getaway_invitations/`,
      data
    })
  },

  putGetawayInvitation: async (id: string, data: { status: string }) => {
    return await apiRequest({
      method: 'PUT',
      url: `getaway_invitations/${id}`,
      data
    })
  },

  deleteGetawayInvitation: async (id: string) => {
    return await apiRequest({
      method: 'DELETE',
      url: `getaway_invitations/${id}`
    })
  },

  createGetaway: async (userID: number, data: Partial<GetawayData>) => {
    return await apiRequest({
      method: 'POST',
      url: `users/${userID}/getaways/`,
      data
    })
  },

  putGetawayPhoto: async (id: number, data: FormData) => {
    return await apiRequest({
      method: 'POST',
      url: `/getaways/${id}/photo`,
      data,
      form: true
    })
  },

  getUsers: async (query: string) => {
    return await apiRequest({
      url: '/users/search',
      data: { q: query }
    })
  },

  getUserAchievements: async (userID: string) => {
    return await apiRequest({
      url: `users/${userID}/achievements/`
    })
  },

  getAchievements: async () => {
    return await apiRequest({
      url: '/achievements/',
      appAuth: true
    })
  },

  getGroups: async (query: string) => {
    return await apiRequest<GroupData[]>({
      url: '/groups/',
      data: { q: query }
    })
  },

  getChallenge: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: `challenges/${id}/`,
      appAuth: !token
    })
  },

  joinChallenge: async (id: string | number) => {
    return await apiRequest<{
      id: number
      user_id: number
      challenge_id: number
      created_at: string
      updated_at: string
    }>({
      method: 'POST',
      url: `challenges/${id}/join/`,
      appAuth: !token
    })
  },

  leaveChallenge: async (id: string) => {
    return await apiRequest({
      method: 'POST',
      url: `challenges/${id}/leave/`,
      appAuth: !token
    })
  },

  getCustomChallenge: async (id: string) => {
    return await apiRequest({
      method: 'GET',
      url: `custom_challenges/${id}/`,
      appAuth: !token
    })
  },

  joinCustomChallenge: async (id: string) => {
    return await apiRequest({
      method: 'POST',
      url: `custom_challenges/${id}/join/`,
      appAuth: !token
    })
  },

  leaveCustomChallenge: async (id: string) => {
    return await apiRequest({
      method: 'POST',
      url: `custom_challenges/${id}/leave/`,
      appAuth: !token
    })
  },

  getChallenges: async (isoCode: string) => {
    return await apiRequest({
      method: 'GET',
      url: '/challenges/',
      appAuth: !token,
      data: {
        country: isoCode
      }
    })
  },

  geoCode: async (item: GeoCodeItem) => {
    return await apiRequest({
      url: 'geo/geocode/',
      appAuth: !token,
      data: item
    })
  },

  reverseGeoCode: async (data: GeoSuggestionData) => {
    return await apiRequest({
      url: 'geo/reverse',
      appAuth: !token,
      data
    })
  },

  getGeoSuggestions: async (data: GeoSuggestionData) => {
    return await apiRequest({
      url: 'geo/suggest',
      appAuth: !token,
      data
    })
  },

  getWeather: async (latitude: number, longitude: number) => {
    return await apiRequest({
      method: 'GET',
      url: '/weather',
      data: { latitude, longitude }
    })
  },

  resetPassword: async (email: string) => {
    return await apiRequest({
      method: 'POST',
      url: 'reset_password_email/',
      data: { email }
    })
  },

  setPassword: async (password: string, token: string) => {
    return await apiRequest({
      method: 'POST',
      url: 'set_password',
      data: { token, password }
    })
  },

  consumeVoucher: async (voucher: string) => {
    return await apiRequest<{
      pro_days: number
      redeemed: boolean
      revenuecat_offer_id: string | null
      stripe_price_id: string | null
    }>({
      method: 'POST',
      url: 'vouchers/use/',
      data: { voucher }
    })
  },

  getTermsOfService: async (locale: string) => {
    return await apiRequest({
      method: 'GET',
      url: 'tos_object/?language=' + locale
    })
  },

  acceptTermsOfService: async () => {
    return await apiRequest({ url: 'accept_tos', apiPath: '' })
  },

  getLocation: async () => {
    return await apiRequest({ url: 'geoip' })
  },

  createStripeCheckoutSession: async (
    stripePriceId: string,
    voucher?: string,
    stripePromoCode?: string,
    referralId?: string,
    skipTrial: boolean = false
  ) => {
    return await apiRequest<{ client_secret: string }>({
      method: 'POST',
      url: 'stripe/create_checkout_session',
      data: {
        stripe_promotion_code: stripePromoCode,
        price_id: stripePriceId,
        voucher,
        skip_trial: skipTrial,
        referral: referralId
      }
    })
  },

  createStripeBillingPortalSession: async () => {
    return await apiRequest<{ url: string }>({
      method: 'POST',
      url: 'stripe/create_billing_portal_session'
    })
  }
}

export type RISERAPI = typeof API
