{
  "%@ finished a trip": "{0} ha terminato un percorso",
  "Starting-Point": "Punto di partenza",
  "Fastest": "Diretto",
  "duration": "durata",
  "Long": "allungata",
  "Short": "corta",
  "Medium": "mezzo",
  "Export-as-gpx": "Esportazione in GPX",
  "Export-and-save": "Esportazione e salvataggio",
  "destination": "destinazione",
  "Hours": "orario",
  "Hold on a second. We are calculating your route": "Per favore, aspetta un attimo. Stiamo calcolando il tuo percorso.....",
  "Please-type-in-%@-to-confirm": "Digita {0} per confermare.",
  "My Account": "Il mio account",
  "Go to Settings": "Vai alle impostazioni",
  "End-Date": "Data di fine",
  "Start-Date": "Data d'inizio",
  "Invite-Users": "invitare gli utenti",
  "HAVE-A-PRO-X-MAS": "HAVE A PRO X-MAS!",
  "Our-way-to-say-thank-you-get-percentage-first-year": "Grazie per aver fatto parte della comunità RISER. \n\nTi diamo il {0} di sconto sul tuo primo anno RISER PRO.",
  "First-year": "1° anno",
  "No-commitment-cancel-at-any-time": "Nessun obbligo, rescindibile in qualsiasi momento.",
  "then": "dopo {0}",
  "%@ added a new bike": "{0} ha aggiunto una moto nuova",
  "%@ and you are now friends on RISER": "{0} e tu ora siete amici su RISER",
  "%@ sent you a friend request": "{0} ti ha mandato una richiesta d'amicizia",
  "%@ liked your section": "A {0} piace la tua sezione",
  "%@ commented on your section": "{0} ha lasciato un commento sulla tua sezione",
  "%@ liked your trip": "A {0} piace il tuo percorso",
  "%@ commented on your trip": "{0} ha lasciato un commento sul tuo percorso",
  "%@ liked your bike": "A {0} piace la tua moto",
  "%@ commented on your bike": "{0} ha lasciato un commento sulla tua moto",
  "%1@ and %2d others like this": "{0} e altri {1} come questo",
  "%1d bikers drove here %2d times": "{0} motociclisti sono passati qui {1} volte",
  "%@ likes this": "Piace a {0}",
  "%d bhp": "{0} cavalli",
  "%d friends": "{0} amici",
  "%d hour(s) ago": "{0} ora\/e fa",
  "%d minute(s) ago": "{0} minuto\/i fa",
  "%d of %d": "{0} di {1}",
  "%d people like this": "Piace a {0} persone",
  "%d second(s) ago": "{0} secondo\/i fa",
  "Accept": "Accetta",
  "Account & Settings": "Profil & impostazioni",
  "Add Bike": "Aggiungi moto",
  "Add comment...": "Aggiungi commento...",
  "Add friend": "Aggiungi amico\/a",
  "Add to my Roadbook": "Aggiungi al mio giornale di bordo",
  "An unknown error occured. We are sorry for the inconvenience.": "Errore non identificabile. Ci scusiamo per il disagio.",
  "and": "e",
  "Anyone": "Tutti",
  "Are you sure you want to delete this photo?": "Sei sicuro di voler cancellare questa foto?",
  "Are you sure you want to discard this trip?": "Sei sicuro di voler cancellare questo percorso?",
  "Are you sure you want to report this user?": "Sei sicuro di voler segnalare questo utente?",
  "Are you sure? This action can not be undone.": "Sei sicuro? Questa azione non può essere ripristinata.",
  "avg speed": "Ø Velocità",
  "Basic": "Basic",
  "Build year (optional)": "Anno di costruzione (opzionale)",
  "Build year": "Anno di costruzione",
  "By signing up you accept our": "Con la registrazione accetti",
  "Cancel": "Cancella",
  "Caution!": "Attenzione!",
  "Change Password": "Cambia password",
  "Check out RISER at https:\/\/riserapp.com\/": "Da un' occhiata a RISER anche qui https:\/\/riserapp.com\/",
  "choose bike": "Scegli moto",
  "Choose Brand": "Scegli marchio",
  "Choose build year": "Scegli anno di costruzione",
  "Choose Model": "Scegli modello",
  "City": "Città",
  "Climb": "Ascesa",
  "Climb \/ Fall": "Ascesa \/ Discesa",
  "Coast": "Costiera",
  "Complete Signup": "Completa accesso",
  "Confirm new password": "Conferma password nuova",
  "Connect with Facebook": "Connetti con Facebook",
  "Continue with Facebook": "Continua con Facebook",
  "Create Section": "Crea sezione",
  "Create special sections and share them with the community": "Crea sezioni speciali e condividile con la community",
  "Curves": "Curve",
  "Current Password": "Password attuale",
  "Dark": "Scuro",
  "Decline": "Rifiuta",
  "Delete Account": "Cancella profilo",
  "Delete Bike": "Cancella moto",
  "Delete Section": "Cancella sezione",
  "Delete Trip": "Cancella percorso",
  "Description": "Descrizione",
  "Desert": "Deserto",
  "Discover popular roads with live weather and forecast": "Scopri strade popolari con il meteo in diretta e la previsione del tempo",
  "Discover": "Scopri",
  "Display": "Schermo",
  "Distance": "Distanza",
  "edit friends": "Cambia amici",
  "Edit Bike": "Cambia moto",
  "Edit Photo": "Cambia foto",
  "Edit Section": "Cambia sezione",
  "Edit Trip": "Cambia percorso",
  "Email": "E-mail",
  "Engine Power (optional)": "Forza motore (opzionale)",
  "Engine Power": "Forza motore",
  "Enter current password": "Inserisci la password attuale",
  "Enter new password": "Inserisci una password",
  "Enter your name in order to make it possible for your friends to find you...": "Inserisci il tuo nome, così anche i tuoi amici riescono a trovarti...",
  "Error": "Errore",
  "Facebook": "Facebook",
  "Feed": "Feed",
  "Finish": "Finisci",
  "First Name": "Nome",
  "Forgot password?": "Hai dimenticato la tua password?",
  "Friday Ride": "Percorso del venerdì",
  "Friends": "Amici",
  "Garage": "Garage",
  "Go!": "Vai!",
  "Heads up!": "All'erta!",
  "How did it go?": "Com'è andata?",
  "How was the weather?": "Che tempo ha fatto?",
  "Imperial": "Imperiale",
  "Info": "Informazioni",
  "Invalid email \/ password combination": "Combinazione e-mail \/ password invalida",
  "Invite friends": "Invita amici",
  "Invite your friends to join RISER": "Invita i tuoi amici ad unirsi a RISER",
  "Join us at RISER": "Unisciti a RISER",
  "Just now": "Adesso",
  "kmh": "km\/h",
  "Last Name": "Cognome",
  "Link to trip": "Link del percorso",
  "Loading weather...": "Carica meteo...",
  "Log out": "Log out",
  "Logging you in...": "Effettuazione login...",
  "Login": "Login",
  "Looking for matching sections...": "Ricerca di sezioni correspondenti...",
  "Manufacturer": "Manufattore",
  "Map Type": "Tipo di mappa",
  "Matching sections": "Sezioni corrispondenti",
  "Max Speed": "Velocità massima",
  "Membership": "Membership",
  "Message": "Messaggio",
  "Messenger": "Messenger",
  "Metric": "Metric",
  "Miscellaneous": "Misto",
  "Model": "Modello",
  "Monday Ride": "Percorso del lunedì",
  "More": "Altro",
  "Mountain": "Montagne",
  "mph": "cavalli",
  "My Profile": "Mio profilo",
  "New Password": "Password nuova",
  "Next": "Prossimo",
  "Nickname (optional)": "Soprannome (opzionale)",
  "Nickname": "Soprannome",
  "No Internet Connection": "Connessione internet assente",
  "No matching sections found.": "Nessuna sezione trovata.",
  "No note added.": "Nessun appunto aggiunto.",
  "Notifications": "Notifiche",
  "Offroad": "Fuoristrada",
  "Ok": "Ok",
  "Only me": "Solo io",
  "Oops!": "Oops!",
  "Open Settings": "Apri impostazioni",
  "or": "O",
  "Passwords have to consist of min 8 and max 32 characters.": "Le password devono essere costituite almeno di 8 ed al massimo di 32 caratteri.",
  "Password": "Parola d'accesso",
  "Password reset": "Ripristina parola d'accesso",
  "Pause": "Pausa",
  "Pending": "In attesa",
  "Photo Library": "Bacheca fotografie",
  "Photo": "Foto",
  "Photos": "Foto",
  "Photos along this section": "Foto riprese con questa sezione",
  "Please choose at least a brand and a model to add a bike to your collection.": "Inserisci al minimo un marchio e un modello da aggiungere alla tua collezione di moto.",
  "Please enter a valid email address": "Inserisci un indirizzo e-mail valido",
  "Please enter an email and a password": "Ti preghiamo di inserire indirizzo e-mail e parola d'accesso",
  "Please provide the email address you registered with at RISER: ": "Per favore, fornisci l'indirizzo e-mail che hai usato per registrarti su RISER:",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Pro": "PRO",
  "Rank": "Classifica",
  "Remove from roadbook": "Cancella dal giornale di bordo",
  "Report User": "Segnala utente",
  "Requested": "Richiesto",
  "Resume": "Riprendi",
  "RISER Feedback": "RISER feedback",
  "RISER TV": "RISER TV",
  "Roadbook": "Giornale di bordo",
  "Saturday Ride": "Percorso del sabato",
  "Save": "Salva",
  "Sea level (max\/min)": "Altitudine (max\/min)",
  "Secret Link": "Link segreto",
  "Section Title": "Titolo sezione",
  "Successfully added to your roadbook": "Aggiunto al tuo giornale di bordo",
  "Section": "Sezione",
  "Sections": "Sezioni",
  "Send Feedback": "Invia feedback",
  "Settings": "Impostazioni",
  "Share Link": "Link per condivisione",
  "Show Photo": "Vedi foto",
  "Signing you up...": "Accesso in corso...",
  "Sorry, we couldn't establish a connection to the server.": "Ci dispiace, non è stato possibile stabilire una connessione con il server.",
  "Sponsored Post": "Contenuto sponsorizzato",
  "Start Trip": "Vai!",
  "Step 1\/2": "Passo 1\/2",
  "Step 2\/2": "Passo 2\/2",
  "Streets": "Strade",
  "Success": "Successo",
  "Summary": "Sommario",
  "Sunday Ride": "Percorso della domenica",
  "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?": "Raccontaci cosa ne pensi di RISER. Cosa ti piace? Dove possiamo migliorare? Quali funzioni mancano, secondo te?",
  "Terms of Service": "Condizioni di servizio",
  "Terrain": "Topografico",
  "The email address you entered already exists": "L'indirizzo e-mail inserito esiste già",
  "The password you entered doesn't match your current password.": "La parola d'accesso che hai inserito non corrisponde alla tua parola d'accesso attuale.",
  "The passwords you entered don't match.": "Le parole d'accesso inserite non corrispondono.",
  "Thursday Ride": "Percorso del giovedì",
  "Time": "Tempo",
  "Top speed": "Velocità massima",
  "Track your trips and organize them in your personal roadbook": "Registra i tuoi percorsi e organizzali nel tuo giornale di bordo",
  "Trips": "Percorsi",
  "Tuesday Ride": "Percorso del martedì",
  "Turn on to generate share link": "Attiva per creare link per condividere",
  "Unexpected Error": "Errore imprevisto",
  "Unfriend": "Sconnetti l'amicizia",
  "Units": "Pezzi",
  "Update": "Aggiornamento",
  "Update failed": "Aggiornamento fallito",
  "Updating bike...": "Aggiornamento moto...",
  "Updating password...": "Aggiornamento parola d'accesso",
  "Updating section...": "Stiamo aggiornando la sezione...",
  "Updating trip...": "Stiamo aggiornando il percorso...",
  "Updating user credentials...": "Stiamo aggiornando i dettagli dell'utente",
  "Uploading bike...": "Caricamento moto...",
  "Uploading section...": "Caricamento sezione...",
  "Uploading trip...": "Caricamento percorso...",
  "Version": "Versione",
  "Scenery": "Panorama",
  "Warning": "Avvertimento",
  "Water": "Acqua",
  "Wednesday Ride": "Percorso del mercoledì",
  "Welcome back!": "Ben tornato! Ben tornata!",
  "Welcome to the family!": "Benvenuto! Benvenuta!",
  "Who can see your trip?": "Chi può vedere il tuo percorso?",
  "Who was with you on the road?": "Chi è stato in viaggio con te?",
  "Yes": "Sì",
  "You and %d others like this": "Piace a te e {0} altri",
  "You have to grant RISER access to Facebook in order to login.": "Consenti a RISER l'accesso a Facebook per poterti connettere.",
  "You like this": "Ti piace",
  "Your password was changed successfully.": "La tua parola d'accesso è stata cambiata.",
  "hp": "Cavalli",
  "Camera": "Fotocamera",
  "Gallery": "Bacheca",
  "Nothing-to-show-in-your-feed-yet": "Niente da visualizzare nel tuo feed...",
  "Connect-with-your-friends-on-RISER-in-order-to-see": "Connetiti con amici su RISER per vedere le loro attività",
  "Open-one-of-your-trips-and-create-interesting-sect": "Apri uno dei tuoi percorsi e crea sezioni interessanti anche per altri.",
  "Send": "Invia",
  "Are-you-sure-you-want-to-delete-your-bike": "Sicuro di voler cancellare la tua moto?",
  "Are-you-sure-you-want-to-delete-this-section": "Sicuro di voler cancellare questa sezione?",
  "Do-you-want-to-log-out": "Vuoi effettuare il log out?",
  "Select": "Seleziona",
  "No-title": "Senza titolo",
  "%@-ride": "{0} percorso",
  "Monday": "Lunedì",
  "Tuesday": "Martedì",
  "Wednesday": "Mercoledì",
  "Thursday": "Giovedì",
  "Friday": "Venerdì",
  "Saturday": "Sabato",
  "Sunday": "Domenica",
  "Share-using": "Condividi con...",
  "Would-you-like-to-unfriend": "Rimuovi dagli amici?",
  "Accept-Friendship": "Accetta richiesta?",
  "Offline": "Offline",
  "Sign-Up": "Registrati",
  "You-can-add-photos-to-your-profile-and-your-trips": "Puoi aggiungere delle foto al tuo profilo e ai tuoi percorsi.",
  "No-bikes-to-show": "Nessuna moto disponibile",
  "Add-your-bikes-and-they-will-appear-here-in-the-li": "Aggiungi le tue moto per visualizzarle nella lista",
  "No-friends-to-show": "Nessun amico disponibile",
  "No-sections-to-show": "Nessuna sezione disponibile",
  "Create-sections-from-within-your-trips-or-add-sect": "Crea sezioni dai tuoi percorsi oppure aggiungi sezioni di altri utenti al tuo giornale di bordo",
  "No-trips-to-show": "Nessun percorso disponibile",
  "No-photos-to-show": "Nessuna foto disponibile",
  "When-you-go-for-a-ride-an-take-photos-along-the-wa": "Quando sei in viaggio con la tua moto, le foto che farai appariranno qui",
  "People-you-might-now": "Altri che potresti conoscere",
  "%d-trips": "{0} percorsi",
  "Please-enter-a-Section-Title": "Inserisci un titolo per la sezione",
  "This-special-part-of-your-trip": "Questa parte del tuo percorso...",
  "Use-the-slider-to-define-the-part-of-your-trip-tha": "Usa lo slider per scegliere la parte \"WOW!\" del tuo percorso",
  "Enter-a-descriptive-name-for-your-section": "Inserisci una descrizione per la sezione",
  "Section-Tags": "Marchi per sezioni",
  "Assign-up-to-5-tags-to-your-section-which-helps-to": "Scegli fino a 5 marchi che ti aiuteranno a categorizzare la sezione",
  "Select-the-photos-you-want-to-share-alongside-this": "Scegli le foto che vuoi condividere con questa sezione",
  "If-you-liked-a-part-of-your-trip-in-particular-you": "Se parte di un itinerario ti piace molto, puoi sceglierne una parte e salvarla come sezione. Le tue sezioni saranno condivise con gli altri utenti su RISER ed aiuteranno loro a trovare nuovi percorsi. Sezioni sono visibili per tutti.",
  "Delete-friend-request": "Cancella richiesta?",
  "loading": "Caricamento in corso",
  "Section-Tags-min-1-max-5": "Marchi sezioni (min 1, max 5)",
  "Please-assign-at-least-one-tag-to-your-new-section": "Ti preghiamo di assegnare almeno un marchio alla tua nuova sezione",
  "Go-for-a-ride-and-your-trips-will-appear-here": "Parti in viaggio ed i tuoi percorsi appariranno qui",
  "A-part-of-your-trip-made-you-feel-%@": "Questa parte del percorso ti ha fatto sentire \"{0}\"?",
  "WOW": "WOW",
  "Got-it": "Tutto chiaro!",
  "Describe-your-section-a-little-bit": "Descrivi il tuo segmento...",
  "Subscribe-to-newsletter": "Iscriviti al newsletter",
  "Delete": "Annullare",
  "Register-now": "Registrati ora!",
  "Want-to-connect": "Vuoi connetterti?",
  "Signing-up-is-free-easy-and-very-fast-Two-simple-s": "Registrarsi è gratis, semplice e fatto in un attimo. Due passi e potrai goderti tutta la RISER experience.",
  "Nice-bike": "Bella moto!",
  "Time-to-create-your-profile": "È ora: Crea il tuo profilo!",
  "Add-your-profile-and-cover-photo-to-give-your-acco": "Aggiungi il tuo profilo personale ed una foto per dare un tocco individuale al tuo account. Registrati per farti trovare anche da altri!",
  "Awesome": "Ottimo!",
  "Time-to-register": "È ora di registrarsi...",
  "Share-your-trip-with-friends": "Condividi con i tuoi amici!",
  "Sign-up-and-create-special-sections-organize-them": "Registrati e crea sezioni speciali, organizzale nel tuo diario di bordo e condividile con altri!",
  "Nice-track": "Bel percorso!",
  "Sign-up-in-order-to-load-sections-into-your-roadbo": "Registrati per poter caricare sezioni nel tuo itinerario di bordo ed organizzarle!",
  "You-finished-a-trip": "Hai terminato un percorso",
  "Start-testing": "Prova ora!",
  "Hide-top-speed": "Nasconi velocità massima",
  "Hide-start-and-end": "Nasconi inizio e fine",
  "Driving": "Guida",
  "Auto-Pause": "Pausa automatica",
  "RISER-uses-acceleration-data-to-provide-you-with-d": "RISER usa dati di accelerazione per poter creare le statistiche dettagliate dei tuoi percorsi.",
  "Create-getaway": "Crea Getaway",
  "Journey": "Viaggio",
  "Cruising": "Cruising",
  "Dynamic": "Dinamico",
  "create": "Crea",
  "Visibility": "Visibilità",
  "Details": "Dettagli",
  "Conversation": "Conversazione",
  "Title": "Titolo",
  "Start-Time": "Inizio del viaggio",
  "End-Time": "Fine del viaggio",
  "Meeting-Point": "Luogo d'incontro",
  "Getaway-Duration": "Durata del getaway",
  "Driving-Style": "Stile di guida",
  "Short-Spin": "Uscita veloce",
  "Day-Trip": "Viaggio di un giorno",
  "Sporty": "Sportivo",
  "Give-others-an-idea-of-how-long-your-getaway-shoul": "Inserisci qualche informazione su quanto durerà il tuo getaway.",
  "Select-a-riding-style-so-others-know-what-to-expec": "Seleziona uno stile di guida così altri sapranno cosa aspettarsi da questo getaway.",
  "Comfy-riding-speed-with-several-breaks": "Stile guida comodo con alcune pause",
  "More-swifty-driving-style-than-cruising-with-the-o": "Stile guida veloce, non tanto cruising, con poche pause",
  "Sporty-driving-style-and-not-too-many-breaks": "Stile guida sportivo, con poche pause",
  "Duration": "Durata",
  "see-all-%d-bikers": "vedi tutti {0} motociclisti",
  "Invited-%d": "Inviti ({0})",
  "Pick-a-date-optional": "Aggiungi una data (opzionale)",
  "Enter-a-title-optional": "Inserisci titolo (opzionale)",
  "Enter-a-detailed-description-optional": "Inserisci una descrizione dettagliata (opzionale)",
  "a-few-hours": "1-2 ore",
  "several-hours": "diverse ore",
  "several-days": "diversi giorni",
  "Search": "Cerca...",
  "Add": "Aggiungi",
  "Close": "Chiudi",
  "Search-address": "Cerca indirizzo...",
  "Getaway": "Getaway",
  "AnyoneGetawayExplanation": "Il tuo getaway sarà visibile per tutti, anche nell'area scopri ed eventualmente anche nel feed. Tutti potranno partecipare.",
  "OnlyMeGetawayExplanation": "Il tuo getaway sarà visibile solo per te e coloro che hai invitato. Non apparirà ne nell'area scopri ne da nessun' altra parte.",
  "FriendsGetawayExplanation": "Il tuo getaway apparirà nell'area scopri ai tuoi amici ed anche nel loro feed. Solo i tuoi amici potranno partecipare.",
  "AnyoneExplanation": "Tutti possono vedere questo elemento. Sarà visibile per le persone che visitano il tuo profilo e potrebbero apparire nei risultati di ricerca.",
  "FriendsExplanation": "Solo i tuoi amici su RISER possono vedere questo elemento. Sarà visibile solo per i tuoi amici nel tuo profilo e non verrà visualizzato nei risultati di ricerca ad altre persone.",
  "OnlyMeExplanation": "Tu sarai l'unico a vedere questo elemento. Non apparirà nel tuo profilo e non verrà visualizzato in nessun risultato di ricerca.",
  "Private": "Privato",
  "My-getaways": "I miei getaways",
  "Find-getaways": "Trova getaways",
  "Getaways": "Getaways",
  "My-Roadbook": "Il mio diario di bordo",
  "Creating-getaway": "Crea getaway...",
  "Invitations-%d": "Inviti ({0})",
  "Upcoming-%d": "Prossimo ({0})",
  "Past-%d": "Dopo il ({0})",
  "TBA": "Ancora da definire",
  "Want-to-go-for-a-ride-with-others": "Vuoi uscire in moto con altri?",
  "Discussion": "Discussione",
  "Share": "Condividi",
  "Attending": "Parteciperà",
  "Edit": "Cambia",
  "Participants": "Partecipanti",
  "Remove": "Togli",
  "Remove-user": "Togli utente",
  "Do-you-want-to-remove-%@-from-the-getaway": "Vuoi cancellare {0} dal getaway?",
  "Processing": "Elaborazione in corso...",
  "Featured-%d": "Featured ({0})",
  "Browse": "Naviga",
  "Getaways-nearby-%d": "Getaways nelle vicinanze ({0})",
  "Me": "Io",
  "Participants-%d": "Persone che partecipano ({0})",
  "Featured": "Featured",
  "Are-you-sure-you-want-to-delete-this-getaway": "Sei sicuro di voler eliminare questo getaway?",
  "Delete-Getaway": "Elimina getaway",
  "Edit-Getaway": "Cambia getaway",
  "Organizer": "Organizzatore",
  "Participating": "Partecipa",
  "You-have-an-upcoming-getaway-with-%@": "C'è un getaway che ti aspetta con {0}",
  "%@-created-a-getaway": "{0} ha creato un getaway",
  "No-messages-to-show-Be-the-first-to-start-this-con": "Non ci sono messaggi. Inizia tu una conversazione.",
  "Leave-Getaway": "Abbandona Getaway",
  "Leave": "Abbandona",
  "You-created-a-getaway": "Hai creato un getaway",
  "Want-to-invite-people-to-a-trip": "Vuoi invitare altri ad uscire in moto?",
  "Create-a-getaway-set-a-date-and-meeting-point-and": "Crea un getaway, imposta data e luogo d'incontro ed invita i tuoi amici. Non è mai stato così facile organizzare un viaggio in moto per te ed i tuoi amici!",
  "Participate": "Partecipa",
  "%@-invited-you-to-a-getaway": "{0} invitati al tuo getaway",
  "You-are-the-organizer": "Tu sei l'organizzatore",
  "%1@-changed-the-details-of-getaway-%2@": "{0} ha cambiato i dettagli del getaway {1}",
  "%1@-commented-on-getaway-%2@": "{0} ha lasciato un commento sul getaway {1}",
  "%1@-changed-the-meeting-point-and-start-time-of-ge": "{0} ha cambiato il luogo d'incontro e l'ora di partenza del getaway {1}",
  "%1@-will-attend-your-getaway-%2@": "{0} parteciperà al tuo getaway {1}",
  "%1@-changed-the-meeting-point-of-getaway-%2@": "{0} ha cambiato il luogo d'incontro del getaway {1}",
  "%1@-changed-the-start-time-of-getaway-%2@": "{0} ha cambiato l'ora di partenza del getaway {1}",
  "You-are-not-participating-in-any-getaways-yet-Crea": "Al momento non partecipi a nessun getaway. Crea un getaway tuo ed invita i tuoi amici ad un percorso in moto!",
  "There-are-no-getaways-around-your-current-location": "Crea un getaway pubblico e sarà visibile nei risultati di ricerca per altri motociclisti nelle tue vicinanze.",
  "No-getaways-to-display": "Nesun getaway disponibile",
  "Silent-here-so-far": "Tutto calmo qui, finora",
  "You-have-to-accept-our-Terms-of-Service-in-order-t": "Devi accettare le nostre condizioni di servizio per poter usare l'applicazione.",
  "SMS-or-Email": "Messaggio o e-mail",
  "Groups": "Gruppi",
  "My-Groups": "I miei gruppi",
  "Getaways-enable-you-to-organize-joint-trips-with-y": "Getaways ti aiutano ad organizzare uscite in moto con amici. Specifica lo stile di guida per un getaway per dare un' idea ad altri su cosa aspettarsi. Aggiungi ora e luogo d'incontro - e siete pronti per partire!",
  "Plan-joint-trips-with-your-friends": "Organizza uscite in moto con amici",
  "Worldwide": "In tutto il mondo",
  "Closed": "Chiuso",
  "Secret": "Segreto",
  "Public": "Pubblico",
  "Request-pending": "Richiesta in attesa",
  "Respond-to-invitation": "Rispondi all'invito",
  "Group-Privacy": "Privacy del Gruppo",
  "Tap-to-set-home-base": "Tocca per impostare la home base",
  "Request-access": "Richiedi accesso",
  "You-are-a-member": "Sei un membro",
  "Group-home-base": "Home base del gruppo",
  "Enter-a-short-description-for-your-group": "Inserisci una breve descrizione del tuo gruppo",
  "Group-name": "Nome del gruppo",
  "Create-group": "Crea gruppo",
  "Group-members-%d": "Membri del gruppo ({0})",
  "You-are-the-owner": "Tu sei il proprietario",
  "%d-members": "{0} membri",
  "Join-group": "Unisciti al gruppo",
  "Admin": "Amministratore",
  "Enter-group-name": "Inserisci il nome del gruppo",
  "%1@-accepted-the-invitation-to-the-group-%2@": "{0} ha accettato l'invito ad unirsi al gruppo {1}",
  "%1@-wants-to-join-the-group-%2@": "{0} vuole unirsi al gruppo {1}",
  "%1@-invited-you-to-join-the-group-%2@": "{0} ti ha invitato ad unirti al gruppo {1}",
  "%1@-modified-the-group-%2@": "{0} ha modificato il gruppo {1}",
  "Do-you-want-to-remove-%@-from-the-group": "Vuoi rimuovere {0} dal gruppo?",
  "PublicGroupExplanation": "Qualunque utente che si sia iscritto a RISER può trovare un gruppo pubblico ed unirvisi. Per unirsi a un gruppo pubblico non è necessario inviare alcuna richiesta all'amministratore.",
  "ClosedGroupExplanation": "Ogni utente di RISER può trovare questo gruppo. Per potersi unire ad un gruppo chiuso è necessario richiedere l'accesso e l'autorizzazione all'amministratore.",
  "SecretGroupExplanation": "Solo i membri e gli utenti invitati possono trovare o vedere il gruppo segreto e le informazioni ad esso relative. Gli utenti posso unirsi solo se sono stati invitati dall'amministratore. Il gruppo è invisibile a coloro che non sono membri.",
  "Member-%d": "Membro ({0})",
  "Owner-%d": "Proprietario ({0})",
  "Edit-group": "Modifica gruppo",
  "Delete-group": "Cancella gruppo",
  "No-groups-to-display": "Nessun gruppo da mostrare",
  "Are-you-sure-you-want-to-delete-this-group?": "Sei sicuro di voler cancellare questo gruppo?",
  "Are-you-sure-you-want-to-leave-this-group?": "Sei sicuro di voler lasciare questo gruppo?",
  "Manage-requests": "Gestisci richieste",
  "Hey-%@!": "Ehi! {0}!",
  "Do-you-have-a-bike-in-your-garage?-You-can-add-it-here-to-get-detailed-statistics-on-how-much-distance-and-time-you-spend-on-it.-Other-bikers-will-be-able-to-check-it-out-when-they-visit-your-profile.": "Hai una moto in garage? Puoi aggiungerla qui per usufruire di statistiche dettagliate sulle distanze che percorri e il tempo che le dedichi. Gli altri motociclisti potranno darle un'occhiata quando visitano il tuo profilo.",
  "Thank-you!": "Grazie!",
  "Oh-no!": "Oh no!",
  "Please-tell-us-what-we-can-do-better-or-what-you-are-still-missing.-Your-feedback-helps-us-to-improve-the-app-constantly.": "Ti invitiamo a farci sapere cosa possiamo migliorare o cosa vorresti trovare che al momento manca. Il tuo feedback ci aiuta a migliorare costantemente l'app.",
  "Pending-Invitations": "Inviti in Attesa",
  "Membership-Requests": "Richieste di Adesione",
  "Admin-area": "Area amministratore",
  "Invite": "Invita",
  "Accept-all": "Accetta tutto",
  "Manage-members": "Gestisci i membri",
  "You-want-to-be-part-of-a-group?": "Vuoi unirti ad un gruppo?",
  "Offline-maps": "Mappe offline",
  "Please-enter-a-group-name": "Inserisci un nome per il gruppo",
  "Creating-group": "Creazione gruppo in corso",
  "This-group-name-is-already-taken,-please-choose-another-one": "Questo nome non é piú disponibile, scegli un' altro",
  "Requests": "Richieste",
  "Members": "Membri",
  "Groups-nearby-(%d)": "Gruppi nelle tue vicinanze ({0})",
  "%1@-joined-the-group-%2@": "{0} si é unito al tuo gruppo {1}",
  "You-joined-the-group-%@": "Ti sei unito al gruppo {0}",
  "Request-pending-%d": "Richiesta in attesa {0}",
  "Cancel-request": "Cancella richiesta",
  "No": "No",
  "Share-trip": "Condividi percorso",
  "Brand": "Marchio",
  "Enter-missing-brand-name": "Inserisci il nome del marchio mancante",
  "Enter-missing-model-name": "Inserisci il nome del modello mancante",
  "Can't-find-your-brand?": "Non trovi il marchio della tua moto?",
  "Can't-find-your-model?": "Non trovi la tua moto?",
  "Current-Location": "Posizione attuale",
  "Route-%d": "Itinerario {0}",
  "Enable-live-tracking-and-send-a-link": "Attiva il LiveTracking ed invia un link alla tua famiglia ed amici in modo che possano seguirti sulla mappa.",
  "reWind": "reWind",
  "Adventure-Routing": "Adventure Routing",
  "Find-the-curviest-roads-with-the-most-beautiful-scenery": "Trova le strade più ricche di curve con i paesaggi più belli e trasforma il tuo viaggio in un'avventura.",
  "We-get-you-from-A-to-B": "Ti portiamo da A a B. Magari non sul percorso più veloce, ma quello più eccitante.",
  "Tracking": "Registra percorso",
  "Navigating": "Navigazione",
  "Adventure-Navigator": "Adventure Navigator",
  "Curvy": "Curve",
  "Supercurvy": "Super curve",
  "Max-Duration": "Durata massima",
  "Dont-have-time?": "Non hai tempo?",
  "Live-Tracking": "LiveTracking",
  "Fastest-way": "Percorso più veloce",
  "Apply": "Applica",
  "Share-rewind-link": "Condividi reWind Link",
  "Download": "Scarica",
  "Show-rewind": "Mostra reWind",
  "Caution-when-activating-fastest-way": "Attenzione! Attivando la navigazione più veloce disabiliterai Adventure Routing e potresti finire sulle strade e autostrade più noiose.",
  "Where-do-you-want-to-go": "Dove vuoi andare {0}?",
  "Group-names-must-be-between": "I nomi di gruppi devono essere compresi tra 2 e 500 lettere",
  "Invalid-file-type-only-jpg-png-allowed": "Tipo di file non valido. Sono ammessi solo JPG e PNG",
  "Select-area": "Seleziona l'area",
  "Roundtrip": "Andata e ritorno",
  "We-are-sorry-we-couldnt-find-a-route-with-the-specified-settings": "Siamo spiacenti, non abbiamo trovato un percorso con le impostazioni da te specificate.",
  "Route-options": "Opzioni di percorso",
  "Avoid-motorways": "Evita autostrade",
  "Try-14-days": "Prova per 14 giorni",
  "then-%@-per-year": "invece che {0} \/ anno",
  "Yearly": "Per anno",
  "%@-per-month": "{0} \/ month",
  "Monthly": "mensile",
  "%@-per-year": "{0} \/ anno",
  "save-money": "salva",
  "Get-riser": "Ottieni RISER",
  "go-back-in-time": "Tornare indietro nel tempo? Nella tua avventura con RISER puoi! Guarda di nuovo il tuo reWind su una mappa 3D interattiva e condividi con i tuoi amici.",
  "No-connection-no-problem": "Nessuna connessione - nessun problema! Nella tua avventura con RISER, Offline Maps ti terrà in pista anche nelle aree più isolate.",
  "curves-are-all-you-need": "Le curve sono tutto ciò di cui hai bisogno? Scopri il tuo itinerario perfetto con RISER Adventure Routing e usa i RISER modes esclusivi per crearlo!",
  "Adventure-navigator-pro": "Adventure Navigator PRO",
  "stay-connected-with-live-tracking": "Rimani in contatto con amici e familiari e tienili aggiornati sulla tua avventura! Invia loro la tua posizione con LiveTracking!",
  "sometimes-its-not-about-where-you-go": "A volte non si tratta di dove vai. Ma come ci arrivi. Migliora la tua avventura personale con le funzioni intelligenti di RISER PRO.",
  "Subscription-terms": "Termini di abbonamento",
  "Something-went-wrong-when-verifying-your-purchase": "Qualcosa è andato storto durante la verifica del tuo acquisto. Ti preghiamo di contattare il nostro servizio clienti.",
  "Before-you-become-a-pro": "Prima di diventare un PRO ...",
  "Contact": "Contatto",
  "Feedback": "Risposta",
  "Agree-and-sign-up": "Accetta e registrati",
  "Would-you-like-to-receive-updates-via-email": "Desideri ricevere aggiornamenti da RISER via email?",
  "Reason": "Ragionare",
  "Ambassador": "Ambassador",
  "%1@-also-commented-on-trip-%2@": " {0} ha anche lasciato un commento sul percorso {1}",
  "%1@-also-commented-on-bike-%2@": " {0} ha anche lasciato un commento sulla moto {1}",
  "%1@-also-commented-on-section-%2@": " {0} ha anche lasciato un commento sulla sezione {1}",
  "average per ride": "media per corsa",
  "since last ride": "dall'ultima corsa",
  "Broken-URL": "\nGli URL rotti sono simili alle batterie scariche: è davvero fastidioso e richiede molto tempo per farli funzionare di nuovo. <br><br> Ma i nostri migliori sviluppatori stanno già cercando di risolvere questo problema.",
  "and-become-part-of-the-community": "e diventare parte della comunità",
  "Already-have-an-account": "Hai già un account?",
  "Sorry-for-the-inconvenience": "Ci scusiamo per l'inconveniente. Non abbiamo potuto procedere al pagamento. La tua carta non è stata addebitata.",
  "Your-purchase-has-been-successfully-applied": "\nIl tuo acquisto è stato applicato con successo. <br> Ora puoi usufruire di tutte le funzioni PRO. <br> Se hai domande, non esitare a contattarci! Buona corsa. <br> Il tuo team RISER",
  "thank-you-name": "Grazie {0} e benvenuto in RISER PRO!",
  "Please-contact-our-service-team-at": "Contatta il nostro team di assistenza all'indirizzo {0}",
  "Suggestions": "Suggerimenti",
  "Discover-even-curvier-routes-with-the": "Scopri anche i percorsi più curvilinei con Adventure Navigator PRO. Non perdere mai di vista la tua destinazione con le Mappe Offline. Traccia i tuoi viaggi e riavvolgili su una mappa 3D. Fai sapere ai tuoi cari dove sei con il LiveTracking.",
  "Extended-route-planning": "Pianificazione del percorso estesa",
  "Watch-your-trip-once-again-on-an-interactive-3D-map-and-share-it-with-your-friends": "\nWatch your trip once again on an interactive 3D-map and share it with your friends.",
  "Never-lose-your-way-when-theres-no-connection-even-in-the-most-remote-areas": "Non perdere mai la strada quando non c'è connessione, anche nelle aree più remote.",
  "Conditions-of-participation": "*Condizioni di partecipazione: Ogni utente di RISER ha a disposizione tanti Mi piace quanti ne vuole assegnare. L'utente è libero di mettere mi piace a tutti i viaggi nominati o solo a uno. L'assegnazione del premio 5 x 1 anno RISER PRO tra gli utenti che partecipano alla votazione avviene tramite sorteggio. Il vincitore o i vincitori saranno avvisati via e-mail al termine del concorso e dopo la pubblicazione del vincitore del concorso. Sono escluse le vincite doppie e non è possibile il rimborso in denaro. Per qualsiasi domanda, contatta il supporto{'@'}riserapp.com",
  "What-bike-were-you-riding": "\nChe moto stavi guidando?",
  "Be-part-of-the-RISER-community": "Entra a far parte della comunità RISER",
  "Enter-name": "Inserisci il nome",
  "Could-not-load-weather": "Impossibile caricare il meteo",
  "Billed-annually": "Fatturato annualmente",
  "Starting at (price)": "A partire da",
  "Free": "Gratis",
  "Forever": "Per sempre",
  "Free_description": "Ottieni l'app e la piattaforma RISER con le sue funzioni di base.",
  "Open-the-app-to-purchase-RISER-PRO": "Apri l'applicazione per acquistare RISER PRO. Se non hai l'applicazione, puoi scaricarla gratuitamente dal tuo app store:",
  "registering-is-free": "la registrazione è gratuita al 100%",
  "Pick-a-date": "Aggiungi una data",
  "settings-account-deletion-disclaimer": "Se cancelli il tuo profilo, tutti i dati associati verranno cancellati dai nostri server. Questa azione è irreversibile. Puoi creare un account nuovo quando vuoi.",
  "Sometimes it’s not about where you go, but how you get there.": "A volte non si tratta di dove si va, ma di come ci si arriva.",
  "Remember-me": "Ricordati di me",
  "Thank you for your trust!": "Grazie per la tua fiducia!",
  "You will now continue receiving our newsletter": "Ora continuerai a ricevere la nostra newsletter, contenente informazioni aggiornate, novità e altro ancora da RISER.",
  "You can always use your right to withdraw this permission": "Puoi sempre avvalerti del diritto di revocare tale autorizzazione, senza fornire alcuna motivazione.",
  "Signup-&-Subscription": "Iscrizione e sottoscrizione",
  "Get-Pro": "Ottieni PRO",
  "Are-you-sure-you-want-to-delete-your-comment?": "Sei sicuro di voler cancellare il tuo commento?",
  "Comments": "Commenti",
  "Start-(footer)": "Iniziare",
  "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>": "<h2>MOMENTI UNICI<\/h2><p>con gli altri<\/p>",
  "Connect with your friends and like-minded people": "Connettiti con persone che la pensano allo stesso modo, crea fughe per pianificare viaggi con i tuoi amici o unisciti ai gruppi per farti ispirare.",
  "CONNECT WITH FRIENDS": "CONNETTERSI CON GLI AMICI",
  "CREATE OR JOIN GROUPS": "CREARE O UNIRSI A GRUPPI",
  "SHARE YOUR EXPERIENCE": "CONDIVIDI LA TUA ESPERIENZA",
  "<h2>GO PRO!<\/h2> <p>Individual adventures with RISER PRO<\/p>": "<h2>GO PRO!<\/h2><p>Avventure individuali con RISER PRO<\/p>",
  "RISER PRO provides new sophisticated features for you": "RISER PRO ti offre nuove e sofisticate funzionalità: Trova i tuoi itinerari perfetti utilizzando il Navigatore Avventura PRO con le impostazioni: percorso curvilineo, percorso supercurvilineo, percorso più veloce da A a B o andata e ritorno. Condividi la tua posizione in tempo reale con il LiveTracking. Non perderti mai con le Mappe Offline. Tornare indietro nel tempo? Guarda una rappresentazione animata del percorso che hai fatto con reWind su una mappa 3D interattiva che puoi condividere con gli altri tramite un link.",
  "Treat yourself with a 14 days free trial now!": "Concediti subito 14 giorni di prova gratuita!",
  "Empower yourself to experience new adventures": "Potenziati per vivere nuove avventure",
  "FIND YOUR IDEAL ROUTE": "TROVA IL TUO PERCORSO IDEALE",
  "Curvy? Super curvy? Yeah, we know you don’t like straight roads.": "Curvy? Super curvose? Sì, sappiamo che non ti piacciono le strade dritte. Ecco perché il Navigatore Avventura RISER individua i percorsi più belli con il maggior numero di curve per te, se usi queste preferenze quando pianifichi il tuo prossimo viaggio con il Navigatore Avventura RISER PRO. Da A a B o andata e ritorno, lasciati ispirare dalle migliori curve del mondo e goditi il viaggio con la Navigazione Turn by Turn!",
  "NEVER GET LOST": "NON PERDERSI MAI",
  "Be on the move and do not lose your orientation": "Non perdere l'orientamento, anche se la connessione alla rete mobile non è più disponibile, grazie a Mappe Offline. Mappe Offline rende disponibile offline l'intero materiale cartografico (la tua rispettiva selezione) in modo che tu possa sempre accedervi.",
  "GO BACK IN TIME": "TORNA INDIETRO NEL TEMPO",
  "Each driven trip is unique and each route special in their own way.": "Ogni viaggio guidato è unico e ogni percorso è speciale a modo suo. Per poter rivedere il tuo viaggio in seguito, RISER offre la possibilità di condividere una mappa interattiva realizzata con materiale cartografico 3D per ogni viaggio salvato. Puoi ingrandire o ridurre la mappa dinamica in qualsiasi momento o cambiare la prospettiva. Verranno mostrate anche foto e informazioni statistiche e potrai condividere la mappa tramite link con i tuoi amici.",
  "SHARE YOUR POSITION": "CONDIVIDI LA TUA POSIZIONE",
  "On a longer motorcycle ride, as a RISER PRO user you can send your position": "Durante un viaggio in moto più lungo, come utente di RISER PRO puoi inviare la tua posizione sul percorso a familiari o amici: Con la funzione LiveTracking, RISER offre la possibilità di inviare un link con la tua posizione per dare a chi guarda una panoramica del viaggio o dove potrebbe essere il prossimo punto di incontro per unirsi al viaggio.",
  "Newsletter": "Newsletter",
  "The road is never straight for us": "La strada non è mai dritta per noi: se vuoi rimanere aggiornato sulle novità, sui viaggi più belli e sulle storie degli altri, puoi iscriverti alla newsletter:",
  "The RISER community explores beautiful routes every day": "<p>La comunità di RISER esplora ogni giorno percorsi meravigliosi, arricchendo l'app e la piattaforma di ispirazione e di tanti percorsi che diventano fonte di ispirazione per gli altri.<\/p>\n\n<p>Perché non c'è un premio per la scoperta di percorsi fantastici? Giusto, non lo sappiamo nemmeno noi! Ecco perché abbiamo creato il RISER Adventure Award: un premio per il quale devi solo fare ciò che ti piace di più: andare in moto!<\/p>",
  "What is the RISER Adventure Award?": "Cos'è il Premio Avventura RISER?",
  "From now on, RISER will award the prize annually": "<p>D'ora in poi RISER assegnerà il premio ogni anno, ma a decidere chi vincerà sarà la community: dieci utenti si contenderanno il premio con un viaggio ciascuno.<\/p>\n\n<p>La comunità di RISER mette i \"Mi piace\" agli itinerari preferiti dei candidati per un mese. Chi avrà più Mi Piace alla fine, vincerà!<\/p>",
  "How do you become a nominee?": "Come si diventa candidati?",
  "Discover together": "Scoprire insieme",
  "Track your adventures": "Segui le tue avventure",
  "Navigate to your destination": "Naviga verso la tua destinazione",
  "After": "dopo",
  "Save your memories": "salvare i tuoi ricordi",
  "Share unique moments": "condividere momenti unici",
  "Inspire other riders": "ispirare altri motociclisti",
  "Riders about RISER": "Motociclisti su RISER",
  "RISER is designed logically and easy to use": "RISER è progettato in modo logico e facile da usare. Offre tutto ciò di cui ho bisogno per il motociclismo. Continuate il buon lavoro!",
  "The app is clean and super-easy to use": "L'applicazione è pulita e super facile da usare. La registrazione dei viaggi funziona senza intoppi e posso documentare facilmente le mie corse con foto, ecc. Raccomandato l'applicazione a tutti i miei amici.",
  "This app is awesome": "Questa applicazione è fantastica. Recentemente ho trovato delle belle strade nella mia zona che non conoscevo nemmeno.",
  "Find your personal adventure": "Trova la tua avventura personale",
  "The best routes are waiting out there and RISER will find them for you.": "I percorsi migliori vi aspettano e RISER li troverà per voi.",
  "Navigation": "Navigazione",
  "Be part of this adventure": "Partecipare a questa avventura",
  "Sign up now!": "Iscriviti ora!",
  "pack ride": "PackRide",
  "Pack Ride Unlimited": "PackRide Illimitato",
  "Upgrade-(pro)": "Aggiornamento",
  "Subscribed-until": "Iscritto fino a",
  "Your-RISER-Team": "Il tuo team RISER",
  "Something-went-wrong-with-your-payment": "Si è verificato un problema con il pagamento...",
  "We-sent-a-verification-mail-to-%@": "Abbiamo inviato un' email di verifica a {0}",
  "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.": "Prima di poter eseguire l'upgrade a RISER PRO, assicurati che l'indirizzo email che hai fornito sia corretto in modo che possiamo inviarti le ricevute.",
  "Verify your email address": "Verifica il tuo indirizzo email",
  "An email has been sent to %@ before you can continue please click": "È stata inviata un'e-mail a {0} prima di continuare, clicca sul link che ti abbiamo inviato per verificare.",
  "Email sent": "Email inviata",
  "Email verified": "Email verificata",
  "Your email address has been verified": "Il tuo indirizzo email è stato verificato",
  "Securely pay %@": "Pagamento sicuro {0}",
  "Continue with payment": "Continuare con il pagamento",
  "Subtotal": "Totale parziale",
  "Total": "Totale",
  "Discount": "Sconto",
  "Support": "Supporto",
  "Never miss out on the best routes and news with our Newsletter": "Non perdere mai le notizie e i tour più interessanti della tua comunità motociclistica su RISER!",
  "Congrats!": "Congratulazioni!",
  "If you need support please get in touch with %@": "Ci dispiace per l'inconveniente. Se hai bisogno di supporto, contattaci a {0}",
  "Code expired": "Codice scaduto",
  "Sorry, too late! This voucher is no longer active.": "Scusa, troppo tardi! Questo buono non è più attivo.",
  "Already used": "Già usato",
  "You already used this voucher.": "Hai già usato questo buono.",
  "Ride together, stay together": "Guidate insieme, rimanete insieme",
  "Enjoy the full Pack Ride experience with RISER PRO": "Goditi l'esperienza completa di PackRide con RISER PRO: non perdere mai il tuo gruppo e invia o ricevi notifiche.",
  "Get an even more sophisticated experience": "Ottieni un'esperienza ancora più sofisticata",
  "Get PRO now": "Ottieni PRO ora",
  "What are you waiting for?": "Cosa stai aspettando?",
  "come": "Vieni",
  "Ride With US": "Vai con noi",
  "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.": "Diventa parte del RISER pack. Ti daremo la possibilità di rendere più memorabili le esperienze in moto che poi potrai condividere con altri.",
  "Join the pack!": "Unisciti al pack!",
  "Never loose your group and send or receive notifications.": "Non perdere mai il tuo gruppo e invia o ricevi notifiche. (Per un'esperienza illimitata PackRide - più di 30 minuti - il leader del pack deve avere un abbonamento RISER PRO)",
  "voucher_success_message": "Ora avete accesso alle funzioni di RISER PRO! \n\nIl tuo account verrà automaticamente resettato a Basic dopo la scadenza del periodo specificato.",
  "Already Pro": "Hai già RISER PRO",
  "voucher_error_already_pro_message": "Hai già un abbonamento PRO. Questo buono può essere utilizzato solo dagli utenti di RISER Basic. \n\nTuttavia, puoi condividere questo voucher con i tuoi amici per dare loro PRO.",
  "current period until": "Periodo corrente fino a",
  "Your email address could not be verified": "Impossibile verificare l'indirizzo e-mail",
  "Find out how": "Scopri come",
  "Show your support for female riders!": "Mostrate il vostro sostegno alle motocicliste!",
  "Show your support for female riders by doing a pack ride!": "DIMOSTRA IL TUO SOSTEGNO ALLE CICLISTE FACENDO UN GIRO DI GRUPPO! RISER ti offre 2 mesi di RISER PRO gratis per partecipare.",
  "We use technical and analytics cookies to ensure that we give you the best experience on our website": "Utilizziamo cookie tecnici e analitici per assicurarti di darti la migliore esperienza sul nostro sito web.",
  "This website uses cookies": "Questo sito web utilizza i cookie",
  "Continue to profile": "Continua al profilo",
  "Group": "Gruppo",
  "Never miss out on the hottest news & tours from your motorcycle community on RISER!": "Non perdere mai le notizie e i tour più interessanti della tua comunità motociclistica su RISER!",
  "Email Format": "Formato e-mail",
  "Marketing Permissions": "Autorizzazioni di marketing",
  "Please select all the ways you would like to hear from RISER:": "Si prega di selezionare tutti i modi in cui si desidera sentire da RISER:",
  "Email - get the best stories and news": "Email - ricevi le migliori storie e notizie",
  "Customized Online Advertising - get offers tailored to your preferences": "Pubblicità online personalizzata - ottieni offerte su misura per le tue preferenze",
  "You can unsubscribe at any time by clicking the link in the footer of our emails.": "Puoi annullare l'iscrizione in qualsiasi momento cliccando sul link nel piè di pagina delle nostre e-mail.",
  "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.": "Usiamo Mailchimp come nostra piattaforma di marketing. Cliccando qui sotto per iscriverti, riconosci che le tue informazioni verranno trasferite a Mailchimp per l'elaborazione.",
  "Learn more about Mailchimp's privacy practices here.": "Scopri di più sulle procedure sulla privacy di Mailchimp qui.",
  "Biannually": "Semestralmente",
  "6_months_PRO_title": "6 mesi PRO",
  "Currently not on your phone? Open your RISER App now and get started!": "Attualmente non sul telefono? Apri subito la tua app RISER e inizia!",
  "Are you sure you want to leave this getaway?": "Sei sicuro di voler lasciare questo getaway?",
  "Invited": "Invitato",
  "The App for Motorcyclists": "L'app per motociclisti",
  "Available for free": "Disponibile gratuitamente",
  "Login with Email": "Accedi tramite e-mail",
  "Register with Email": "Registrati tramite e-mail",
  "What are you looking for?": "Che cosa stai cercando?",
  "We are sorry but we could not find any results for your search…": "Siamo spiacenti ma non abbiamo trovato nessun risultato per la tua ricerca ...",
  "Users": "Utenti",
  "Enter start": "Inserisci inizio",
  "Enter waypoint": "Inserisci il waypoint",
  "Enter Destination": "Inserisci destinazione",
  "Sign Up or Login to join the conversation": "Iscriviti o accedi per partecipare alla conversazione",
  "Bookmarked": "segnalibro",
  "Created": "Creato",
  "6_months_PRO_description": "Ottenete i vostri 6 mesi PRO e godete di tutte le funzionalità PRO per metà anno!",
  "One time payment": "Pagamento una tantum",
  "1_year_PRO_description": "Ottieni tutte le funzioni PRO e migliora la tua esperienza!",
  "We have updated our Terms of Service": "Abbiamo aggiornato i nostri Termini di servizio. Si prega di consultare l'ultima versione qui sotto.",
  "1_year_PRO_title": "1 anno PRO",
  "Free_title": "Basic",
  "Already verified": "Già verificato",
  "Resend verification link": "Reinvio del link di verifica",
  "Cancel subscription": "Annullamento dell'abbonamento",
  "Subscription Period": "Periodo di abbonamento",
  "Product": "Prodotto",
  "Invoice Status": "Stato della fattura",
  "invoice_draft": "Bozza",
  "invoice_open": "Aperto",
  "invoice_paid": "Pagato",
  "invoice_void": "Vuoto",
  "invoice_uncollectible": "Non recuperabile",
  "No open payments and already PRO": "Non ci sono pagamenti aperti e avete già tutti i vantaggi di RISER PRO! Grazie per il vostro continuo supporto.",
  "Country": "Paese",
  "Payment details": "Dettagli del pagamento",
  "Card holder": "Proprietario della carta",
  "Street address": "Indirizzo stradale",
  "State (address)": "Stato",
  "ZIP Code": "Codice postale",
  "Card number": "Numero di carta",
  "Secure customer authentication required before payment can be processed.": "L'autenticazione sicura del cliente è richiesta prima che il pagamento possa essere elaborato.",
  "Card invalid.": "Carta non valida.",
  "Period": "Periodo",
  "Amount due": "Importo dovuto",
  "Remember that you have to manually cancel any mobile subscriptions.": "Ricordate che dovete cancellare manualmente qualsiasi abbonamento mobile nel rispettivo App Store.",
  "To delete your account you first need to cancel your active subscription.": "Per eliminare il tuo account devi prima cancellare il tuo abbonamento.",
  "Invalid password reset token.": "Token di reset password non valido.",
  "Send password reset email": "Richiedi l'e-mail di reimpostazione della password",
  "No user with this email address was found.": "Non è stato trovato nessun utente con questo indirizzo e-mail.",
  "Type in your e-mail and we will send you a link to reset your password.": "Inserisci il tuo indirizzo e-mail e ti manderemo un link.",
  "An email has been sent to %@ please follow the instructions inside to reset your password.": "È stata inviata un'e-mail a {0}. Segui le istruzioni per reimpostare la tua password.",
  "Upgrade now and become a PRO!": "Aggiorna ora e diventa un PRO!",
  "Discover PRO plans": "Scopri i piani PRO",
  "and more to come...": "e altri in arrivo...",
  "Subscribe now!": "Iscriviti ora!",
  "Austria": "Austria",
  "Belgium": "Belgio",
  "Bulgaria": "Bulgaria",
  "Croatia": "Croazia",
  "Cyprus": "Cipro",
  "Czech Republic": "Repubblica Ceca",
  "Denmark": "Danimarca",
  "Estonia": "Estonia",
  "Finland": "Finlandia",
  "France": "Francia",
  "Germany": "Germania",
  "Greece": "Grecia",
  "Hungary": "Ungheria",
  "Ireland": "Irlanda",
  "Italy": "Italia",
  "Latvia": "Lettonia",
  "Lithuania": "Lituania",
  "Luxembourg": "Lussemburgo",
  "Malta": "Malta",
  "Netherlands": "Olanda",
  "Poland": "Polonia",
  "Portugal": "Portogallo",
  "Azores": "Azzorre",
  "Madeira": "Madeira",
  "Mainland (Continental Portugal)": "Continente (Portogallo continentale)",
  "Romania": "Romania",
  "Slovakia": "Slovacchia",
  "Slovenia": "Slovenia",
  "Spain": "Spagna",
  "Sweden": "Svezia",
  "United Kingdom": "Regno Unito",
  "You are almost there!": "Ci sei quasi!",
  "Street and number": "Via e numero civico",
  "(optional) Apartment, building, floor": "(opzionale) Appartamento, edificio, piano",
  "Tax region": "Regione",
  "Pay invoice": "Paga la fattura",
  "If you have any questions, please let us know: %@": "Se hai delle domande, ti preghiamo di comunicarcele: {0}",
  "now -%@%": "ora -{0}%",
  "14 day free trial": "14 giorni di prova gratuita",
  "only for the annual plan": "solo per il piano annuale",
  "%@-per-6-months": "{0} \/ 6 mesi",
  "Copied to Clipboard": "Copia negli appunti",
  "Copy": "Copia",
  "or share on": "o condividi su",
  "Challenges": "Sfide",
  "Your Rank": "Tuo grado",
  "Your Distance": "Tua distanza",
  "Accept the Challenge": "Accetta la sfida",
  "Leave Challenge": "Lasciare la sfida",
  "Leaderboard": "Classifica",
  "No-challenges-to-show": "Al momento non ci sono sfide",
  "We couldn't find any of your Facebook": "Non siamo riusciti a trovare i tuoi amici di Facebook perché non hai collegato Facebook a RISER. Clicca sul pulsante per collegare Facebook e RISER.",
  "Your Duration": "La tua durata",
  "Upcoming": "Prossimo",
  "Past": "Completato",
  "Current": "Attuale",
  "This-is-a-mystery-user": "Questo motociclista non ha aggiunto una descrizione. Ma perché?",
  "Follow": "Seguire",
  "About": "Riguardo",
  "Planned": "Pianificato",
  "Ride this now": "Vai ora in moto",
  "General": "Generale",
  "Short Description": "Descrizione breve",
  "Social Media": "Social Media",
  "Likes": "Likes",
  "Add Posting": "Aggiungi un messagio",
  "Post": "Post",
  "Add Photo": "Aggiungi una foto",
  "ktm-challenge-title": "7 Giorni, 1000 Chilometri, 5 - 11 Luglio 2021",
  "ktm-challenge-terms": "Accetto i <a target=\"_blank\" href=\"https:\/\/riserapp.com\/en\/waw-terms\"> termini e condizioni di RISER <\/a> e <a target=\"_blank\" href=\"https:\/\/www.ktm.com\/en-int\/world-adventure-week\/terms---conditions.html\"> Termini e condizioni di KTM <\/a> della World Adventure Week",
  "State\/Province": "Stato\/Provincia",
  "Your Score": "Il tuo punteggio",
  "Total Points": "Punti totali",
  "Challenge": "Sfida",
  "Your Challenge Bike": "La tua Challenge Bike",
  "You don’t have any planned routes so far": "Non hai ancora pianificato alcun itinerario. Usa l'app o apri routeplanner.pro sul tuo computer per pianificare la tua prossima avventura!",
  "Are you sure you want to delete this planned route": "Sei sicuro di voler eliminare questo percorso?",
  "No planned Routes": "Nessun percorso pianificato",
  "save_your_route_to_your_roadbook": "Salva il tuo percorso nel roadbook",
  "Name your planned route": "What should be the name of your planned route",
  "save_planned_route_successful": "Il percorso è stato salvato nel roadbook.",
  "join for free": "Iscriviti gratuitamente",
  "Minimum 8 characters": "Minimo 8 caratteri",
  "Continue with Google": "Continua con Google",
  "Log in to RISER": "Accedi a RISER",
  "Start": "Iniziare",
  "Skip": "Salta",
  "Add your first bike to your garage": "Add your first bike to your garage",
  "Add your bike to get detailed statistics about your rides and more.": "Add your bike to get detailed statistics about your rides and more.",
  "Set your season goal": "Stabilisci il tuo obiettivo stagionale",
  "How far do you want to ride this season?": "Quanto vuoi guidare in questa stagione?",
  "What are you most interested in?": "A cosa sei più interessato?",
  "Connect with friends": "Connettiti con gli amici",
  "Find_exciting_routes": "Trova percorsi emozionanti",
  "Share_my_rides_with_others": "Condividere le mie corse con gli altri",
  "Organize common rides": "Organizza corse comuni",
  "Tell us something about you": "Raccontaci qualcosa di te",
  "Homebase": "Homebase",
  "Continue": "Continua",
  "Stay_up_to_date_and_get_the_latest_news": "Rimani aggiornato e ricevi le ultime notizie",
  "Choose Allow receive notifications and emails": "Scegli \"Consenti\" per ricevere notifiche ed e-mail sulle tue corse e sulle storie degli amici e della comunità.",
  "Allow": "Consentire",
  "Later": "Più tardi",
  "number_month": "%d mese|%d mesi",
  "Welcome to \u2028RISER,!": "Benvenuto a RISER, {0}!",
  "You are now all set for your first ride": "Ora sei pronto per la tua prima corsa, per esplorare nuove strade e per competere con gli altri nelle sfide. Divertiti!",
  "Today": "Oggi",
  "Unlock and test all PRO features like": "Sblocca e prova tutte le funzioni PRO come LiveTracking, PackRide, percorsi supercurvi e molto altro ancora per {0} giorni gratuitamente.",
  "You will receive a reminder about when your trial ends.": "Riceverai un promemoria al termine della prova.",
  "You will be charged for the subscription. Cancel anytime before.": "L'abbonamento ti verrà addebitato. Puoi disdire in qualsiasi momento prima di.",
  "price year month after trial": "{0} \/ anno ({1} \/ mesi) dopo la sperimentazione",
  "Start Free Trial": "Iniziare la prova gratuita",
  "You won’t be charged until": "Non ti verrà addebitato nulla fino a {0}. Annulla in qualsiasi momento fino a 24 ore prima della fine del periodo di prova",
  "Unfollow": "Non seguire",
  "Snap": "Direzione",
  "Fullscreen": "Schermo intero",
  "Trip": "Itinerario",
  "Rewind Speed": "Velocità di reWind",
  "Max Altitude": "Altitudine massima",
  "Open Roadbook": "Aprire Roadbook",
  "Clear all Waypoints": "Cancella tutti i waypoint",
  "Add as start point": "Add as start point",
  "Add as intermediate point": "Aggiungere come punto intermedio",
  "Add as end point": "Aggiungere come punto finale",
  "Saving": "Salvare",
  "Direction": "Direzione",
  "North": "Nord",
  "NorthEast": "Nord-Ovest",
  "East": "Est",
  "SouthEast": "Sud-Est",
  "South": "Sud",
  "SouthWest": "Sud-Ovest",
  "West": "Ovest",
  "NorthWest": "Nord-Ovest",
  "Direction_Duration": "Direzione e durata",
  "Random": "Casuale",
  "Done": "Fatto",
  "bio": "Bio",
  "show": "Show",
  "choose_map_type": "Scegliere il tipo di mappa",
  "Statistics": "Statistiche",
  "Save Route to Your Roadbook": "Salvare l'itinerario nel roadbook",
  "Season goal": "Obiettivo stagionale",
  "Current period": "Periodo attuale:",
  "Edit profile": "Modifica profilo",
  "Download the RISER App to discover this route": "Scarica l'App RISER per scoprire questo percorso",
  "Check out the brand new": "🔥 Scopri il nuovo aggiornamento e portate i tuoi viaggi a un livello superiore.",
  "Connect with friends & riders across the globe": "Connettersi con amici e motociclisti in tutto il mondo",
  "Plan your next \u2028adventure": "Pianificate la tua prossima avventura",
  "Record your rides & share them with the community": "Registrate le tue corse e condividetele con la comunità",
  "continue_with_apple": "Continua con Apple",
  "by_adding_your_name_and_homebase_you_make_it_easier_for_your_friends_to_find_you": "Aggiungendo il tuo nome e la tua residenza, renderai più facile per i tuoi amici trovarti.",
  "not_now": "Non ora",
  "Create posting": "Creare un messaggio",
  "Post now": "Invia ora",
  "What do you want to share?": "Cosa volete condividere?",
  "Distance-this-season": "Distanza in questa stagione",
  "Report posting": "Segnalazione",
  "We need your help here": "Abbiamo bisogno del tuo aiuto",
  "In order to evaluate your report": "Per valutare la tua segnalazione abbiamo bisogno di alcune informazioni da parte tua. Scegli una delle categorie e forniscici una breve descrizione in modo da poter procedere rapidamente con la tua richiesta.",
  "Inappropriate Language": "Linguaggio inappropriato",
  "Spam or Scam": "Spam o truffa",
  "Sexual harassment": "Molestie sessuali",
  "Other": "Altro",
  "Almost there": "Ci siamo quasi...",
  "Please provide us with a short": "Ti preghiamo di fornirci una breve descrizione con parole tue. In questo modo sarà più facile per noi capire e valutare i rispettivi contenuti.",
  "Why do you want to report this comment posting": "Perché vuoi segnalare questo commento\/postilla?",
  "Thank you for reporting": "Grazie per la segnalazione",
  "Our team will look into the matter": "Il nostro team si occuperà della questione il prima possibile.",
  "Elevation": "Elevazione",
  "Thank you for your feedback": "Grazie per il tuo feedback. Lo esamineremo al più presto.",
  "Report this": "Segnala questo",
  "Delete this postings": "Cancellare questo posting",
  "Delete this comment": "Cancellare questo commento",
  "Press control to change angle": "Premere <strong>Controllo + Trascinamento<\/strong> con il mouse per modificare l'angolo",
  "You": "Tu",
  "Show more": "Mostra di più",
  "load more comments": "caricare altri commenti",
  "new bike": "nuova moto",
  "x_comments": "commento|commenti",
  "x_likes": "Like|Likes",
  "posting": "Posting",
  "Unlock all PRO features": "Sbloccate tutte le funzioni PRO e portate la vostra prossima corsa in moto a un livello superiore.",
  "your special offer": "la tua offerta speciale {0}",
  "percent OFF forever": "{0} di sconto",
  "Get PRO for __ now": "Ottieni PRO per il {0} ora",
  "only": "solo",
  "4 Days left": "{0} giorni rimasti",
  "PRO membership": "Iscrizione PRO",
  "Whats_included": "Cosa è incluso:\nPackRide illimitato, percorsi super curvilinei, mappe offline, reWind, LiveTracking, esportazione GPX e molto altro...",
  "Achievements": "Risultati",
  "completed": "completato",
  "__completed": "{0} completato",
  "Share this": "Condividi questo",
  "profile": "Profilo",
  "Please use the app to redeem this voucher": "Utilizzate l'app per riscattare questo buono",
  "Title is not unique": "Titolo già acquisito",
  "error": "Errore",
  "Scan the code to download the app": "Scansiona il codice per scaricare l'app.",
  "Become PRO to get the best out of RISER": "Diventa PRO per ottenere il massimo da RISER.",
  "action_load": "Carico",
  "Route": "Itinerario",
  "Plan now": "Pianifica ora",
  "Order by": "Ordina per",
  "Creation Date": "Data di creazione",
  "Start your own group ride or join an existing one": "Inizia la tua corsa di gruppo o unisciti a un gruppo per vedere dove sono i tuoi amici in tempo reale.",
  "Stop": "Fermati",
  "Choose options": "Scegli le opzioni",
  "Join": "Entra",
  "packrides_nearby": "PackRides nelle vicinanze",
  "Double click to add new destination": "<strong>Doppio clic<\/strong> per aggiungere una nuova destinazione.",
  "A simple double click adds a new destination to your route": "Un semplice doppio clic aggiunge una nuova destinazione al tuo percorso.",
  "Right Click to get additional options": "<strong>Clic destro<\/strong> per ottenere opzioni aggiuntive.",
  "With a right click you get the option to add a new start point, destination or waypoint in between": "Con un clic destro, hai l'opzione di aggiungere un nuovo punto di partenza, destinazione o punto di passaggio in mezzo.",
  "Drag & drop waypoints to change the order": "<strong>Trascina e rilascia<\/strong> i punti di passaggio per cambiare l'ordine.",
  "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire": "Trascina semplicemente un punto di passaggio e rilascialo per organizzare facilmente l'ordine dei punti di passaggio e ottenere esattamente l'itinerario desiderato.",
  "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination": "Senza un punto intermedio, è troppo lontano. Si prega di pianificare questo percorso con punti intermedi fino a questa destinazione.",
  "Congratulations, %@": "Congratulazioni,\n{0} 🎉",
  "You unlocked a new achievement": "Hai sbloccato un nuovo obiettivo.|Hai sbloccato {0} nuovi obiettivi.",
  "achievement unlocked": "Obiettivo \nsbloccato 🎉",
  "Get your code": "Ottieni il tuo codice",
  "on your next cardo device": "sul tuo\nsuccessivo\nCardo\ndispositivo",
  "PRO only": "Solo PRO",
  "your_special_cardo_offer": "La tua offerta speciale",
  "cardo_offer_code_text": "Utilizza il codice qui sotto per ottenere uno sconto speciale del {0}% sul tuo prossimo dispositivo Cardo (escluso Packtalk Pro).",
  "visit_cardo_shop": "Visita Cardo",
  "Route Planner": "Pianificatore",
  "start_action": "Avvia",
  "update_soft_title": "Aggiornamento disponibile",
  "update_hard_title": "Devi aggiornare questa app",
  "update_message": "Abbiamo apportato molte migliorie alla nostra app. Si prega di aggiornare alla versione più recente.",
  "update_now": "Aggiorna ora",
  "Direct competition": "Concorrenza diretta",
  "Enjoying Riser": "Stai apprezzando RISER, {0}?",
  "Your rating helps us grow": "La tua valutazione ci aiuta a crescere e migliorare la nostra app. Mostra il tuo supporto con un rapido tocco!",
  "Thanks for the high-five": "Grazie per il cinque, {0}! 🌟",
  "Would you mind sharing your rating": "Ti dispiacerebbe condividere la tua valutazione su {0}? Ci aiuta davvero e richiede solo un momento!",
  "Maybe next time": "Magari la prossima volta",
  "Submit": "Invia",
  "Rate us in the": "Valutaci nel {0}",
  "%s Followers": "{0} Followers",
  "%s Following": "{0} Segue",
  "Followers": "Followers",
  "Following": "Seguendo",
  "Blocked Users": "Utenti bloccati",
  "Follow back": "Segui di nuovo",
  "Block": "Blocca",
  "Unblock": "Sblocca",
  "Answer": "Rispondi",
  "XY would like to follow you": "{0} vuole seguirti.",
  "Respond to request": "Rispondi alla richiesta.",
  "Delete follower request": "Eliminare la richiesta di seguace?",
  "Are you sure you want to unfollow": "Sei sicuro di voler smettere di seguire {0}?",
  "Are you sure you want to block": "Sei sicuro di voler bloccare {0}?",
  "Your follow request inbox is empty.": "La tua casella delle richieste di follow è vuota. Inizia a connetterti con gli altri inviando loro delle richieste!",
  "You are not following another rider": "Non stai seguendo ancora nessun motociclista. Immergiti nella comunità, scopri altri appassionati e segui i loro viaggi per alimentare la tua avventura!",
  "It's quiet on the road right now with no followers in your rearview": "È tranquillo sulla strada in questo momento, senza seguaci nel tuo specchietto retrovisore. Inizia a condividere le tue avventure in moto e interagisci con la comunità per far partire il tuo viaggio!",
  "Remove follower": "Rimuovi il seguace",
  "Are you sure you want to remove XY": "Sei sicuro di voler rimuovere {0} come tuo seguace?",
  "We couldn't find any riders with this name": "Non siamo riusciti a trovare utenti con questo nome. Forse non è ancora entrato a far parte di RISER? Usa la funzione di invito per portare i tuoi compagni appassionati nella nostra comunità.",
  "XY sent you a follow request": "{0} ti ha inviato una richiesta di follow.",
  "XY accepted your follow request": "{0} ha accettato la tua richiesta di follow.",
  "You got X new follower request": "{0} e un'altra persona ti ha inviato una richiesta di follow.|{0} e altri {0} ti hanno inviato una richiesta di follow.",
  "XY started following you": "{0} ha appena iniziato a seguirti!",
  "XY and others started following you": "{0} e qualcun altro ha appena iniziato a seguirti! 🎉|{0} e altri {0} hanno appena iniziato a seguirti! 🎉",
  "Auto-Accept Followers": "Accetta automaticamente i follower",
  "Accept new followers automatically": "Accetta automaticamente nuovi follower. Semplifica il tuo percorso ed espandi la tua comunità senza sforzo!",
  "empty_text_blocked_users": "Non hai ancora bloccato nessun utente. Il tuo viaggio è senza ostacoli! \n\nContinua a viaggiare e connetterti nello spirito di una grande comunità.",
  "Answer follow request": "Rispondi alla richiesta di seguire",
  "XY sent a request": "{0} ha inviato una richiesta",
  "Introducing our new Follower Logic": "Presentiamo il nostro nuovo sistema di follower",
  "Discover and connect effortlessly with our new follower system": "Scopri e connettiti senza sforzo con il nostro nuovo sistema di follower. Segui la tua passione e ispira gli altri con ogni gita.",
  "Connections preserved": "Connessioni preservate",
  "Your existing friendships are automatically carried over": "Le tue amicizie esistenti vengono automaticamente trasferite. Nessuna azione necessaria — solo connessioni continue e senza soluzione di continuità.",
  "Enable Auto-Accept": "Attiva Accettazione Automatica",
  "Open your journeys to more eyes": "Apri i tuoi viaggi a più occhi! Opta per l'accettazione automatica e lascia che i tuoi tour, viaggi e gite ispirino un pubblico sempre più vasto.",
  "Auto-accept requests": "Accetta automaticamente le richieste",
  "Manual review requests": "Verifica richieste",
  "Save setting": "Salva impostazioni",
  "Mark all read": "Segna tutto come letto",
  "Users on RISER can automatically follow you": "Gli utenti su RISER possono seguirti e interagire automaticamente con te.",
  "Only users that you confirm can follow your rides": "Solo gli utenti che confermi possono seguire i tuoi giri.",
  "Copy link": "Copia il link",
  "Search results for query": "Risultati di ricerca per \"{0}\"",
  "Invite Followers": "Invita follower",
  "Report": "Segnalare",
  "Confirm": "Confermare",
  "Reject": "Rifiutare",
  "block the user": "Blocca utente",
  "Overwrite": "Sovrascrivere",
  "Set Goal": "Imposta obiettivo",
  "Tell us about yourself": "Raccontaci di te",
  "Instagram": "Instagram",
  "Url": "Url",
  "Youtube": "Youtube",
  "Website": "Sito web",
  "Your Climb": "La tua ascesa",
  "Register": "Registrati",
  "Enter your details": "Inserisci i tuoi dettagli",
  "Name": "Nome",
  "Redeem this code using the app": "Riscatta questo codice usando l'app",
  "Invalid Voucher": "Buono non valido",
  "Try again": "Prova di nuovo",
  "Voucher": "Buono",
  "user_state_blocked": "Bloccato",
  "Show this trip": "Mostra questo itinerario",
  "___Participants": "{0} Partecipante|{0} Partecipanti",
  "Show all": "Mostra tutto",
  "Joined": "Iscritto",
  "Suggested": "Suggerito",
  "trending-photo": "Foto di tendenza",
  "Bike": "Moto",
  "Share and reWind": "Condividi e reWind",
  "No comments for this trip": "Nessun commento per questo trip. La tua occasione per essere il primo...",
  "No bike assigned": "Nessuna moto assegnata",
  "special_offer": "Offerta speciale",
  "Share your profile": "Condividi il tuo profilo",
  "pack-ride": "PackRide",
  "export-tours": "Esporta tour",
  "upgrade-pro-yearly-discount-text": "Più popolare",
  "Welcome to RISER Pro": " 🎉 Benvenuto su RISER Pro {0}!",
  "You have now access to all RISER features": "Ora hai accesso completo a tutte le funzionalità di RISER senza alcuna limitazione e non solo, con il tuo acquisto aiuti il nostro lavoro e lo sviluppo di future funzionalità.\n\nGrazie!",
  "Latest added": "Ultimo aggiunto",
  "Select Order": "Seleziona l'ordine",
  "Satellite": "Satellitare",
  "cardo_offer_valid_until": "Questo codice è valido fino al {0}.",
  "new_model": "Nuovo modello",
  "action_suggest_new": "Suggerisci nuovo...",
  "finish-and-continue": "Clicca sul pulsante qui sotto per completare il processo e continuare da dove ti eri fermato.",
  "finish-upgrade": "Termina l’aggiornamento",
  "Avoid tolls": "Evita pedaggi",
  "You can edit the default name of the GPX file": "Puoi modificare il nome del file GPX nel campo di testo prima di esportare.",
  "Export": "Esporta",
  "redeem-voucher-heading": "Riscatta il tuo voucher",
  "redeem-voucher-download-step": "Inizia scaricando la nostra app gratuita e creando il tuo account.",
  "redeem-voucher-action": "Riscatta il voucher",
  "redeem-voucher-redeem-step": "Quindi, clicca sul link qui sotto per riscattare il tuo voucher nell'app.",
  "enjoy-riser-pro": "Goditi RISER Pro!",
  "voucher-invalid-get-pro-button": "Ottieni PRO comunque",
  "Become a RISER PRO": "Diventa\nun RISER\nPRO",
  "And be part of the exclusive PRO rider community.": "E fai parte\ndella comunità\nesclusiva di\nmotociclisti PRO.",
  "Request": "Richiesta",
  "no-trips-yet-text": "Sembra che tu non abbia ancora nessun viaggio.",
  "show-older-comments": "Mostra i commenti più vecchi",
  "profile-image": "Immagine del profilo",
  "cover-image": "Immagine di copertina",
  "invited-to-join-group": "Sei stato invitato a unirti a {0}.",
  "quick-invite": "Invito rapido",
  "this-month": "Questo mese",
  "this-year": "Quest’anno",
  "alltime": "Sempre",
  "group-leaderboard-empty-text": "Sembra che la classifica sia vuota per questo periodo. È la tua occasione!",
  "x-members": "{0} membro|{0} membri",
  "group-join-requested": "{0} ha richiesto di unirsi",
  "group-description-empty-placeholder": "Questo gruppo non ha ancora aggiunto una descrizione.",
  "total-distance": "Distanza totale",
  "total-duration": "Durata totale",
  "total-climb": "Salita totale",
  "create-posting-group-input-placeholder": "Cosa vuoi condividere con il tuo gruppo?",
  "posts": "Post",
  "create-post": "Crea post",
  "group-posting-empty-text": "Sembra che non ci siano ancora post in questo gruppo. Sii il primo a postare qualcosa!",
  "join-my-group-on-riser": "Unisciti al mio gruppo su RISER",
  "share-and-invite": "Condividi & Invita",
  "scan-qr-to-invite": "Scansiona il QR per invitare",
  "check-riser-installed": "Assicurati che l'app RISER sia installata.",
  "share-link": "Condividi link",
  "you-have-no-groups-empty-text": "Sembra che tu non sia in un gruppo. Scopri nuovi gruppi o crea il tuo!",
  "create-your-own-group": "Crea il tuo gruppo",
  "join-group-to-see-more": "Unisciti al gruppo per vedere di più",
  "stats": "Statistiche",
  "no-results-found": "Nessun risultato trovato",
  "something-went-wrong": "Qualcosa è andato storto",
  "Week": "Settimana",
  "Month": "Mese",
  "Year": "Anno",
  "All time": "Sempre",
  "file-to-large-message": "File troppo grande. La dimensione massima del file è {0} MB.",
  "Altitude": "Altitudine",
  "highest": "più alto",
  "lowest": "pù basso",
  "above sea level": "{0} sopra il livello del mare",
  "Week vs week before": "Distanza settimanale",
  "Bikes": "Moto",
  "Countries": "Paesi",
  "Longest Ride": "Viaggio più lungo",
  "Riders in": "Motociclisti in {0}",
  "Month vs month before": "Distanza mensile",
  "Trip distances": "Distanze dei viaggi",
  "Trips per week day": "Viaggi al giorno",
  "Calendar view": "Viaggi al giorno",
  "Year vs year before": "Distanza annuale",
  "Distance per year": "Distanza per anno",
  "Pack Rides": "PackRides",
  "New": "Nuovo",
  "received": "ricevuto",
  "given": "dato",
  "Show all statistics": "Tutte le statistiche",
  "Tools": "Strumenti",
  "Previous": "Precedente",
  "auto-accept-follow-requests": "Accetta automaticamente le richieste di follow",
  "manage-subscription-and-billing": "Gestisci abbonamento e fatturazione",
  "new": "nuovo",
  "Trips per month": "Viaggi al mese",
  "Average:": "Media:",
  "Start week on": "Inizia la settimana il",
  "Fall": "Discesa",
  "Yearly Statistics": "Statistiche annuali",
  "Monthly Statistics": "Statistiche mensili",
  "Strongest Month": "Mese più forte",
  "Strongest Day": "Giorno più forte",
  "Edit profile photo": "Modifica foto profilo",
  "Edit cover photo": "Modifica foto di copertina",
  "People you follow": "Persone che segui",
  "Riders in your country": "Motociclisti nel tuo paese",
  "Set homebase for leaderboard": "Imposta la homebase per la classifica",
  "Postings": "Postings",
  "current-week": "Settimana attuale",
  "week-before": "Settimana prima",
  "average": "Media"
}