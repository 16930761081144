export class NativeClientService {
  attemptIosMessagePost<P>(
    type: 'closeScreen' | 'readyForCommunication' | 'openImage',
    payload?: P,
    payloadVersion: number = 1
  ) {
    if (!window.webkit?.messageHandlers?.iOSMessageHandler) return false

    window.webkit.messageHandlers.iOSMessageHandler.postMessage(
      JSON.stringify({
        type,
        payload: payload ?? null,
        payload_version: payloadVersion
      })
    )

    if (!payload) {
      // send event without payload
      window.webkit.messageHandlers.iOSMessageHandler.postMessage(type)
    }

    return true
  }

  closeScreen() {
    if (window?.Android) {
      window.Android.closeScreen()

      return true
    } else if (this.attemptIosMessagePost('closeScreen')) {
      return true
    }

    return false
  }

  openImage(url: string) {
    if (window?.Android) {
      window.Android.openImage(url)

      return true
    } else if (this.attemptIosMessagePost('openImage', url)) {
      return true
    }

    return false
  }

  readyForCommunication() {
    if (window.Android) {
      window.Android.readyForCommunication()

      return true
    } else if (this.attemptIosMessagePost('readyForCommunication')) {
      return true
    }

    return false
  }

  nativeShare(title: string, text: string, url: string) {
    if (navigator.share && navigator.canShare && navigator.canShare({ title, text, url })) {
      navigator.share({
        title,
        text,
        url
      })

      return true
    } else if (window?.Android) {
      window.Android.nativeShare(title, text, url)

      return true
    }

    return false
  }
}
